/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'cart_full': {
    width: 28,
    height: 24,
    viewBox: '0 0 28 24',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M13.45 22.64c-.63 0-1.13-.54-1.13-1.2 0-.65.5-1.19 1.13-1.19.62 0 1.13.54 1.13 1.19 0 .66-.51 1.2-1.13 1.2zm0-3.75c-1.34 0-2.42 1.14-2.42 2.55 0 1.42 1.08 2.56 2.42 2.56 1.33 0 2.41-1.14 2.41-2.56 0-1.41-1.08-2.55-2.41-2.55zM21.34 22.64c-.62 0-1.13-.54-1.13-1.2 0-.65.51-1.19 1.13-1.19.62 0 1.13.54 1.13 1.19 0 .66-.51 1.2-1.13 1.2zm0-3.75c-1.33 0-2.42 1.14-2.42 2.55 0 1.42 1.09 2.56 2.42 2.56 1.34 0 2.42-1.14 2.42-2.56 0-1.41-1.08-2.55-2.42-2.55zM23.36 14.43H11.53L8.85 5.14h17.19l-2.68 9.29zM28 4.28v-.09l-.01-.05-.01-.03-.01-.04-.01-.04v-.02c-.03-.07-.06-.14-.1-.2l-.04-.05-.01-.02c-.05-.06-.11-.11-.17-.16l-.01-.01-.04-.02-.03-.02-.03-.01-.05-.03h-.02l-.06-.03h-.02l-.06-.02h-.04l-.04-.01H8.36L7.55.62 7.52.55V.53L7.49.48 7.48.45 7.46.42 7.44.38 7.43.37A.523.523 0 0 0 7.27.2L7.25.18 7.21.15A.698.698 0 0 0 7 .04h-.01L6.95.03 6.91.02 6.88.01 6.83 0H.84A.85.85 0 0 0 0 .86c0 .47.38.85.84.85h5.27l3.99 13.82.01.04.02.04.01.03.02.04.01.02.03.05.01.02.04.06.06.06.05.05.02.02.04.03.03.01.03.03.03.01.05.02.03.02.06.02.02.01.05.01.03.01.06.01h13.27l.06-.01.03-.01.04-.01.03-.01.05-.02.04-.02.05-.02.02-.01.04-.03.03-.01.04-.03.02-.02.04-.04.01-.01.05-.06.05-.06.01-.02.03-.05.01-.02.02-.04.01-.03.02-.04.01-.04 3.17-10.99.01-.01v-.02l.01-.06.01-.04.01-.04v-.09z"/>`
  }
})
