/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'empty-star': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0c.492 0 .924.317 1.073.784L15.45 8.25h7.425a1.125 1.125 0 01.689 2.01l-6.131 4.773 2.39 7.5a1.123 1.123 0 01-1.762 1.228l-6.06-4.71-6.062 4.71a1.12 1.12 0 01-1.333.038 1.128 1.128 0 01-.428-1.266l2.39-7.5L.433 10.26a1.123 1.123 0 01.691-2.01h7.43L10.927.783A1.13 1.13 0 0112 0zm0 4.838l-1.552 4.88c-.15.463-.58.782-1.073.782H4.404l4.16 3.24c.375.285.525.777.385 1.227L7.42 19.763l3.891-3.024a1.113 1.113 0 011.378 0l3.89 3.024-1.527-4.796c-.141-.45.009-.942.384-1.228l4.163-3.239h-4.974c-.492 0-.923-.319-1.073-.783L12 4.837z" _fill="#A08F56"/>'
  }
})
