/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'similar-tours': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M29.58 11.5c0 3.6-2.13 7.64-4.45 11.3l.21-.31.87 1.3C28.68 19.9 31 15.55 31 11.5c0-3.51-1.51-6.65-3.88-8.76l-.17.06c-.38-.36-.78-.69-1.21-.98l.11-.05C24.16.65 22.15 0 20 0s-4.16.65-5.86 1.77l.12.05c-.43.29-.83.62-1.21.98l-.17-.06C10.5 4.86 9 7.99 9 11.5c0 4.07 2.3 8.41 4.76 12.29l.87-1.3.23.34c-2.32-3.66-4.44-7.7-4.44-11.33 0-5.52 4.3-10.02 9.58-10.02s9.58 4.5 9.58 10.02zM15.42 23.71l.01-.02-.57-.86.56.88zm1.61 2.43c-.52-.78-1.06-1.59-1.61-2.43l-.56-.88-.23-.34-.87 1.3-.73 1.09c-1.16 1.7-2.17 3.19-2.79 4.43-.62-1.22-1.62-2.69-2.76-4.36-2.74-4.04-6.16-9.06-6.16-13.43 0-5.07 4-9.19 8.92-9.19.92 0 1.8.15 2.64.41l.17.06c.38-.36.78-.69 1.21-.98l-.12-.05a9.96 9.96 0 0 0-3.9-.8C4.6.97 0 5.7 0 11.52c0 4.8 3.55 10.01 6.4 14.21 1.37 2.02 2.69 3.95 3.07 5.14-4.05.64-8.49 1.88-8.49 4.26C.98 38.97 12.63 40 19.51 40c6.89 0 18.54-1.03 18.54-4.87 0-2.19-3.77-3.39-7.59-4.04.28-1.18 1.68-3.25 3.16-5.43C36.46 21.48 40 16.27 40 11.52 40 5.7 35.4.97 29.76.97c-1.38 0-2.7.29-3.91.8l-.11.05c.43.29.83.62 1.21.98l.17-.06c.83-.26 1.72-.41 2.64-.41 4.92 0 8.92 4.12 8.92 9.19 0 4.33-3.41 9.33-6.14 13.36-1.16 1.7-2.17 3.19-2.79 4.43-.62-1.22-1.62-2.69-2.76-4.36l-.78-1.16-.87-1.3-.21.31c-.19.31-.4.59-.59.89-.53.81-1.04 1.62-1.55 2.38-1.24 1.86-2.33 3.48-3 4.83-.66-1.33-1.73-2.93-2.96-4.76zM20.01 34c.39 0 .7-.35.7-.76 0-1.17 1.67-3.68 3.44-6.32.42-.62.85-1.26 1.27-1.91l.49.72c1.37 2.01 2.67 3.93 3.06 5.13-1.71-.24-3.34-.37-4.52-.45-.54-.03-1 .37-1.03.91-.04.54.37 1 .91 1.04 8.53.54 11.61 2.23 11.77 2.75-.29 1.03-6.24 2.94-16.59 2.94-10.34 0-16.3-1.91-16.58-2.91.2-.78 4.27-2.51 12.73-2.94a.96.96 0 0 0 .92-1.02.96.96 0 0 0-1.02-.92c-.76.04-2.48.14-4.47.39.46-1.22 1.71-3.06 3.02-4.99l.44-.65 1.32 1.98c1.75 2.62 3.4 5.09 3.42 6.25v.03c.01.4.33.73.72.73zm4.66-23.77c-.4-.34-1.02-.3-1.37.08l-4.14 4.44-1.5-1.41c-.38-.35-1-.35-1.38 0a.9.9 0 0 0 0 1.3l2.24 2.09c.18.17.43.27.68.27h.03a.99.99 0 0 0 .7-.31l4.83-5.17c.35-.38.31-.96-.09-1.29z"/>`
  }
})
