/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-round': {
    width: 9,
    height: 18,
    viewBox: '0 0 9 18',
    data: '<path pid="0" d="M.91 17.67c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06L6.9 9.87c.48-.48.48-1.26 0-1.74L.38 1.61a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l6.52 6.52c.51.51.8 1.2.8 1.93s-.28 1.42-.8 1.93l-6.52 6.52c-.15.14-.34.22-.53.22z" _fill="#7D7879"/>'
  }
})
