/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'highlights': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M20 25c-.16 0-.32.05-.46.14l-5.65 3.77 1.91-7.02a.825.825 0 0 0-.29-.88l-5.58-4.34h6.74c.34 0 .64-.21.77-.53L20 9.74l2.56 6.4c.13.32.43.53.77.53h6.74l-5.58 4.34c-.27.21-.38.55-.29.88l1.91 7.02-5.65-3.77A.856.856 0 0 0 20 25zm7.5 6.67c.17 0 .34-.06.49-.16.28-.2.41-.56.31-.9l-2.35-8.63 7.06-5.49c.28-.22.39-.59.28-.93a.852.852 0 0 0-.79-.56h-8.6l-3.12-7.81c-.26-.63-1.3-.63-1.55 0L16.1 15H7.5c-.35 0-.67.23-.79.56-.11.34 0 .71.28.93l7.06 5.49-2.35 8.63c-.1.34.03.7.31.9.28.2.66.21.95.02l7.04-4.7 7.04 4.7c.14.09.3.14.46.14zm-7.5-30c10.11 0 18.33 8.22 18.33 18.33S30.11 38.33 20 38.33 1.67 30.11 1.67 20 9.89 1.67 20 1.67zM20 40c11.03 0 20-8.97 20-20S31.03 0 20 0 0 8.97 0 20s8.97 20 20 20z"/>`
  }
})
