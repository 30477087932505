/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'st-marks-basilica--doges-palace-tours': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M16.45 18.21c2.38-.58 4.75-.58 7.13 0l.01-.13c.05-.51.14-1.02.32-1.5.19-.52.19-1.03.05-1.56-.18-.65-.56-1.17-1.05-1.61-.19-.17-.38-.33-.55-.5-.92-.89-1.57-1.93-1.8-3.22l-.07-.36h-.96l-.04.22c-.07.29-.13.59-.22.88-.25.82-.71 1.53-1.29 2.15-.26.27-.53.53-.81.77-.4.36-.75.76-.96 1.26-.28.66-.35 1.33-.09 2.02.14.35.21.72.26 1.1l.07.48zm17.73 16.57H5.84v3.9h28.34v-3.9zm-.65-1.32c.22-4.03-1.48-9.15-6.35-12.27-4.62-2.95-10.56-2.77-15.03.47-2.23 1.61-3.83 3.73-4.81 6.33-.67 1.76-.93 3.59-.86 5.47h27.05zM0 38.67c.05 0 .09.01.14.01l4.23.01h.15c.02-.18.01-.35.01-.52v-4.69h.65c-.03-1.31.06-2.59.33-3.86.28-1.27.7-2.48 1.29-3.63a14.941 14.941 0 0 1 5.04-5.71c1.06-.72 2.2-1.29 3.42-1.71-.02-.18-.03-.36-.05-.54a4.98 4.98 0 0 0-.27-1.14c-.29-.86-.23-1.71.08-2.54.28-.78.76-1.4 1.38-1.92.34-.3.66-.61.95-.96a4.4 4.4 0 0 0 .95-2.12l.03-.14c.06-.3.1-.61.04-.92l-.01-.11.01-.07c.16-.02.31 0 .47-.01h.47V3.99h-3.16V2.68h3.17V0h1.29v2.67h3.26v1.32h-3.25v4.1c.35.03.69 0 1.05.02 0 .08 0 .16-.02.23-.04.23-.04.47.01.7.06.23.1.47.15.7.16.73.52 1.36 1.02 1.91.24.27.51.52.78.76.44.39.83.81 1.11 1.33.4.71.59 1.48.53 2.3-.02.32-.09.62-.2.92-.17.49-.25.99-.28 1.51v.1c3.11 1.1 5.6 3.02 7.47 5.76 1.86 2.74 2.69 5.8 2.59 9.14h.65v5.2l.21.01 4.1.01.21.01V40H0v-1.33z"/>`
  }
})
