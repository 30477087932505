/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'checkmark_circle': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: `<path pid="0" fill-rule="evenodd" _fill="#3AB977" d="M13.11 20.23l-5.19-5.19a.676.676 0 0 1 0-.95c.26-.26.69-.26.95 0l4.24 4.24 8.35-8.36a.676.676 0 0 1 .95 0c.271.26.271.69 0 .95l-9.3 9.31zM15 1.34C7.47 1.34 1.34 7.47 1.34 15S7.47 28.66 15 28.66 28.66 22.53 28.66 15 22.53 1.34 15 1.34zM15 30C6.73 30 0 23.271 0 15 0 6.73 6.73 0 15 0s15 6.73 15 15c0 8.271-6.73 15-15 15z"/>`
  }
})
