/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'louvre-tours': {
    width: 40,
    height: 26,
    viewBox: '0 0 40 26',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M39.36 24.72H.64c-.35 0-.64.28-.64.64 0 .35.29.64.64.64h38.72c.35 0 .64-.29.64-.64 0-.36-.29-.64-.64-.64zm-22.45-7.49c-.35 0-.64.29-.64.64s.29.64.64.64h6.04c.35 0 .64-.29.64-.64s-.29-.64-.64-.64h-6.04zM2.89 22.07c.27.23.68.18.9-.09L20.08 1.67l16.13 20.3c.13.16.31.24.5.24.14 0 .28-.04.4-.14.28-.22.32-.62.1-.9L20.58.24a.635.635 0 0 0-.5-.24.65.65 0 0 0-.5.24L2.79 21.17c-.22.28-.18.68.1.9zm25.7.14c.14 0 .28-.04.4-.14.28-.22.32-.62.1-.9l-8.27-10.3 2.54-3.16c.22-.28.18-.68-.1-.9a.625.625 0 0 0-.89.09L20 9.85 17.63 6.9a.625.625 0 0 0-.89-.09c-.28.22-.32.62-.1.9l2.54 3.16-8.27 10.3c-.22.28-.18.68.1.9.12.1.26.14.4.14.18 0 .37-.08.5-.23L20 11.9l8.09 10.08c.13.15.32.23.5.23z"/>`
  }
})
