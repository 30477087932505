/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'something-different': {
    width: 40,
    height: 34,
    viewBox: '0 0 40 34',
    data: `<path pid="0" _fill="#58B69E" d="M14.612.837c-.378.007-.652.26-.798.49l-2.007 3.999H4.285a4.051 4.051 0 0 0-4.04 4.04v19.755a4.052 4.052 0 0 0 4.04 4.043h31.43a4.052 4.052 0 0 0 4.04-4.043V9.367a4.051 4.051 0 0 0-4.04-4.041h-7.521l-2.005-3.998a.919.919 0 0 0-.802-.491H14.612zm.548 1.796h9.683l1.991 3.985a.918.918 0 0 0 .799.504h8.083a2.22 2.22 0 0 1 2.245 2.245V29.12c0 1.26-.985 2.248-2.245 2.248H4.285a2.221 2.221 0 0 1-2.243-2.248V9.367a2.22 2.22 0 0 1 2.243-2.245h8.083a.915.915 0 0 0 .799-.504l1.993-3.985zM20 10.265c-4.948 0-8.979 4.03-8.979 8.979 0 4.949 4.031 8.979 8.979 8.979 4.949 0 8.979-4.03 8.979-8.98 0-4.947-4.029-8.978-8.979-8.978zm0 1.796a7.17 7.17 0 0 1 7.185 7.183A7.17 7.17 0 0 1 20 26.428a7.17 7.17 0 0 1-7.184-7.184A7.17 7.17 0 0 1 20 12.06z"/>`
  }
})
