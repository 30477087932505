/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'clock': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<path pid="0" fill-rule="evenodd" _fill="#D3D3D3" d="M13.02 9.66v9.75h8.85v-3.15h-5.69v-6.6h-3.16zM16 32c8.84 0 16-7.16 16-16S24.84 0 16 0 0 7.16 0 16s7.16 16 16 16zM3 16C3 8.9 8.78 3.15 15.92 3.15c7.13 0 12.92 5.75 12.92 12.85s-5.79 12.85-12.92 12.85C8.78 28.85 3 23.1 3 16z"/>`
  }
})
