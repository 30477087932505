/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'staff_pick': {
    width: 35,
    height: 33,
    viewBox: '0 0 35 33',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M29.19 6.35h3.58v2.29c0 1.26-.56 2.39-1.48 3.22-.58.52-1.3.92-2.1 1.14V6.35zm-26.96 0h3.46v6.61c-.75-.22-1.43-.6-1.98-1.1-.92-.83-1.48-1.96-1.48-3.22V6.35zm15.21 16.63c-2.63 0-5.01-.96-6.73-2.51-1.73-1.56-2.79-3.69-2.79-6.06V2h19.04v12.41c0 2.37-1.06 4.5-2.79 6.06-1.72 1.55-4.1 2.51-6.73 2.51zM34.67 4.65c-.2-.19-.49-.3-.78-.3h-4.7V1a.97.97 0 0 0-.33-.71c-.21-.18-.49-.29-.79-.29H6.8c-.29 0-.57.11-.78.29a.97.97 0 0 0-.33.71v3.35H1.11c-.29 0-.58.11-.78.3-.21.18-.33.44-.33.7v3.29c0 3.13 2.44 5.74 5.69 6.4v-.63c0 5.5 4.67 10.02 10.64 10.52V31H9.88c-.61 0-1.11.45-1.11 1s.5 1 1.11 1H25c.61 0 1.11-.45 1.11-1s-.5-1-1.11-1h-6.45v-6.07c5.97-.5 10.64-5.02 10.64-10.52v.65C32.5 14.45 35 11.81 35 8.64V5.35c0-.26-.12-.52-.33-.7z"/>`
  }
})
