/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'staff-picks': {
    width: 39.8,
    height: 39.78,
    viewBox: '0 0 39.8 39.78',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M36.6 24.15h-2.49c-.39 0-.71-.32-.71-.71s.32-.71.71-.71h1.07a1.775 1.775 0 1 0 0-3.55H23.45v-1.42c.01-.59.01-2.26 0-9.08 0-6.82-1.52-7.26-2.84-7.26-.75 0-1.81 1.17-1.9 3.03-.08 1.85-.31 3.86-.94 6.65-.49 2.14-3.28 5.22-4.37 6.7-.51.7-.55 2.41-.55 2.41l-.06 16.02c0 .16-.01.32-.03.47.37.42 1.3 1.16 2.97 1.16h17.32c.98 0 1.77-1 1.77-1.99 0-.98-.79-1.77-1.77-1.77h-.36c-.39 0-.71-.32-.71-.71 0-.39.32-.71.71-.71h2.49c.98 0 1.78-.8 1.78-1.78s-.8-1.77-1.78-1.77h-1.07a.715.715 0 0 1 0-1.43h2.49c.98 0 1.78-.79 1.78-1.77s-.8-1.78-1.78-1.78zm-25.23-2.84a3.55 3.55 0 0 0-3.55-3.55H4.98c-1.97 0-3.56 1.59-3.56 3.55v13.5c0 1.96 1.59 3.55 3.56 3.55h2.84a3.55 3.55 0 0 0 3.55-3.55v-13.5zm28.43 4.62c0 1.38-.89 2.55-2.12 3 .43.54.7 1.22.7 1.97 0 1.6-1.18 2.91-2.71 3.15.36.52.57 1.14.57 1.82 0 1.77-1.43 3.41-3.19 3.41h-17.3c-2.33 0-3.54-1.18-3.54-1.18l-.13.26c-.65.86-1.68 1.42-2.84 1.42H3.55A3.55 3.55 0 0 1 0 36.23V19.89a3.55 3.55 0 0 1 3.55-3.55h5.69c1.17 0 2.22.53 2.89 1.37.13-.39.32-.82.65-1.2 1.41-1.62 3.09-3.5 3.57-5.14.95-3.28 1.29-5.65 1.42-7.82C17.89 1.38 19.49 0 20.61 0c1.67 0 4.1.12 4.26 8.52.12 6.03.07 8.23.03 9.24h10.28c1.76 0 3.2 1.43 3.2 3.2 0 .75-.27 1.43-.7 1.97 1.23.44 2.12 1.61 2.12 3z"/>`
  }
})
