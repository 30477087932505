/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subtract': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.828 13.142a4 4 0 000 5.657l11.314 11.314a4 4 0 005.657 0l11.314-11.314a4 4 0 000-5.657L18.799 1.828a4 4 0 00-5.657 0L1.828 13.142zm18.645 6.985a5.878 5.878 0 10-8.312-8.312 5.878 5.878 0 008.312 8.312z" _fill="#5E2013"/>'
  }
})
