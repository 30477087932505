/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lys-flower-white': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data: '<path pid="0" d="M29.494 20.843c-.59.983-1.376 1.573-2.36 1.77h-.982v-.59c0-.394 0-.787-.197-1.18-.197-.59-.59-.787-1.18-.983-.59-.197-1.18 0-1.77.196-.786.197-1.572.787-1.966 1.377-.393.393-.393.786-.59 1.376v.197c.197 0 .197 0 .394.196.393.197.786.394.983.983.196.59.196 1.18-.197 1.573-.393.394-.786.59-1.18.787v.197c0 .393.197.786.59 1.18l.59.59c.393.196.59.59.787.786v.196c0 .197 0 .197-.197.197-.197.197-.59.197-.786.197-.394 0-.787-.197-1.18-.197h-.197c-.393.393-.59.787-.983 1.18-.197.196-.59.59-.983.786-.197 0-.393.197-.59.197h-.393c-.394-.197-.787-.393-.983-.787-.394-.393-.787-.786-.984-1.18h-.196c-.59.197-.983.197-1.573.197-.197 0-.393 0-.59-.196v-.197c.197-.393.393-.59.59-.983.196-.197.59-.393.786-.59.394-.197.59-.59.787-1.18v-.196h-.393c-.59-.197-.984-.59-1.18-.984-.393-.786 0-1.573.59-1.966.196-.196.393-.196.59-.393 0-.393 0-.787-.197-1.18-.393-.786-.983-1.18-1.573-1.573-.59-.197-.983-.393-1.573-.393-.393 0-.787 0-1.18.196-.59.197-.786.787-.983 1.377v1.18c-.787 0-1.376 0-2.163-.394-.786-.393-1.376-1.18-1.77-1.966-.59-1.573-.196-3.146.984-4.522.59-.59 1.376-.984 2.162-1.18 1.573-.197 3.146.196 4.326 1.376.983.787 1.77 1.77 2.36 2.753.393.59.59 1.18.786 1.77v-.787a7.89 7.89 0 00-.59-2.95c-.393-.786-.786-1.572-1.18-2.555-.393-.787-.59-1.573-.982-2.36-.197-.787-.197-1.376-.197-2.163 0-.983.393-1.966.983-2.753.393-.59.983-1.18 1.573-1.77l1.77-1.769h.196c.787.786 1.77 1.573 2.557 2.36.59.59.983 1.376 1.376 2.162.393.787.393 1.573.393 2.36 0 .983-.393 1.966-.786 2.753-.394.786-.59 1.376-.983 2.163-.394.786-.787 1.573-.984 2.556-.196.786-.196 1.573-.196 2.36.196-.197.196-.394.196-.59.59-1.18 1.18-2.163 1.967-3.147.786-.786 1.77-1.573 2.95-1.966.982-.196 1.769-.393 2.752 0 1.18.393 1.966.983 2.556 2.163.983 1.377.983 2.556.393 3.933M17.5 0C7.865 0 0 7.865 0 17.5S7.865 35 17.5 35 35 27.135 35 17.5 27.135 0 17.5 0" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
