/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'logo_small': {
    width: 31.002,
    height: 34.998,
    viewBox: '0 0 31.002 34.998',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M30.412 21.497c-.62 1.27-1.66 2.02-3.01 2.32-.42.09-.85.12-1.28.11l-.11-.01c.03-.21.07-.42.1-.62.06-.53.04-1.05-.16-1.55-.28-.66-.8-1.03-1.48-1.17-.73-.16-1.43-.05-2.12.19-1 .35-1.86.92-2.48 1.81a3.18 3.18 0 0 0-.62 1.76v.16c.15.05.3.09.44.15.55.23.98.59 1.19 1.16.28.73.14 1.4-.31 2.01-.38.51-.89.79-1.5.9-.08.02-.1.04-.09.13.06.6.35 1.08.79 1.46.25.22.52.42.77.62.38.3.71.63.9 1.08l.06.17c.07.22.07.24-.14.35-.32.17-.66.18-1 .17-.54-.01-1.07-.13-1.58-.28-.08-.03-.13-.01-.18.05l-1.24 1.41c-.33.37-.69.71-1.14.92-.22.1-.45.18-.68.2-.19.01-.4-.05-.58-.12-.49-.17-.88-.51-1.22-.89-.45-.5-.89-1-1.33-1.51-.07-.07-.12-.08-.22-.06-.61.18-1.22.31-1.86.28-.29-.01-.56-.07-.8-.24-.08-.04-.09-.09-.07-.18.12-.46.39-.83.74-1.14.3-.26.62-.49.93-.75.44-.36.76-.8.86-1.38l.03-.26c-.14-.03-.27-.06-.4-.1-.69-.22-1.2-.65-1.48-1.32-.4-.94-.08-1.96.79-2.48.25-.15.53-.25.81-.38 0-.46-.1-.93-.31-1.36-.44-.92-1.15-1.54-2.02-2.01-.63-.33-1.3-.54-2.01-.58-.48-.04-.95.02-1.39.23-.72.34-1.05.94-1.14 1.71-.05.44 0 .89.09 1.32l.02.13-.04.02c-.9.02-1.76-.12-2.56-.55a4.202 4.202 0 0 1-2.09-2.56c-.62-2.08-.27-3.97 1.22-5.58.74-.81 1.7-1.24 2.78-1.38 2.05-.26 3.85.35 5.44 1.65 1.19.96 2.08 2.17 2.83 3.5.37.68.7 1.39.99 2.12l.04.1h.04c.02-.31.05-.61.06-.92.03-1.31-.27-2.56-.77-3.76-.43-1.05-.92-2.06-1.38-3.09-.43-.94-.83-1.89-1.1-2.89-.24-.92-.34-1.85-.26-2.8.1-1.24.52-2.35 1.19-3.38.52-.81 1.15-1.52 1.83-2.19.73-.73 1.48-1.44 2.22-2.16.05-.05.1-.05.15 0 1.01 1.02 2.11 1.96 3.07 3.05.69.81 1.3 1.68 1.7 2.68.39.99.54 2.01.48 3.07-.06 1.21-.39 2.35-.87 3.45-.38.91-.81 1.8-1.22 2.7-.47 1.03-.91 2.07-1.18 3.17a8.042 8.042 0 0 0-.18 3.05l.02.04c.13-.3.24-.58.36-.86.65-1.46 1.45-2.81 2.54-3.97 1.02-1.11 2.21-1.95 3.67-2.36 1.14-.32 2.3-.38 3.46-.05 1.43.42 2.46 1.33 3.06 2.7.7 1.6.71 3.22-.07 4.79z"/>`
  }
})
