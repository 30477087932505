/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star_active': {
    width: 36.19,
    height: 33,
    viewBox: '0 0 36.19 33',
    data: '<path pid="0" _fill="#FFB446" d="M18.1 0l4.88 11.79 13.21.81-10.18 8.1L29.27 33 18.1 26.23 6.92 33l3.26-12.3L0 12.6l13.21-.81z"/>'
  }
})
