/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'cvv': {
    width: 29,
    height: 20,
    viewBox: '0 0 29 20',
    data: `<path pid="0" fill-rule="evenodd" _fill="#D6DEE2" d="M25.61 0H3.39C1.52 0 0 1.49 0 3.33v1.11h29V3.33C29 1.49 27.48 0 25.61 0zM11.86 14.44H2.82c-.31 0-.56-.24-.56-.55s.25-.56.56-.56h9.04c.32 0 .57.25.57.56s-.25.55-.57.55zm0 2.23H8.47c-.31 0-.56-.25-.56-.56s.25-.55.56-.55h3.39c.32 0 .57.24.57.55s-.25.56-.57.56zm-5.65 0H2.82c-.31 0-.56-.25-.56-.56s.25-.55.56-.55h3.39c.32 0 .57.24.57.55s-.25.56-.57.56zM0 7.78v8.89C0 18.51 1.52 20 3.39 20h22.22c1.87 0 3.39-1.49 3.39-3.33V7.78H0z"/>`
  }
})
