/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'whatsapp': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2C6.5 2 2 6.5 2 12c0 1.8.5 3.5 1.3 5L2 22l5.2-1.2c1.5.8 3.1 1.2 4.8 1.2 5.5 0 10-4.5 10-10 0-2.7-1-5.2-2.9-7.1C17.2 3 14.7 2 12 2zm0 2c2.1 0 4.1.8 5.7 2.3C19.2 7.9 20 9.9 20 12c0 4.4-3.6 8-8 8-1.3 0-2.7-.3-3.8-1l-.7-.4-.7.2-2 .5.5-1.8.2-.8-.4-.7C4.4 14.8 4 13.4 4 12c0-4.4 3.6-8 8-8zM8.5 7.4c-.2 0-.5 0-.7.3-.3.3-.9.9-.9 2.1 0 1.2.9 2.4 1 2.6.2.2 1.8 2.8 4.3 3.8 2.1.8 2.5.6 3 .6.5-.1 1.5-.6 1.7-1.2.2-.6.2-1.1.3-1.4-.1-.1-.2-.2-.5-.3-.2-.1-1.4-.7-1.7-.8-.3-.1-.4-.2-.6.1-.2.3-.7.8-.8 1-.1.2-.2.2-.5.1-.2-.1-1-.3-2-1.2-.8-.6-1.3-1.4-1.4-1.7-.2-.2 0-.4.1-.5l.4-.4c.1-.1.1-.3.2-.4.1-.1.1-.2 0-.4s-.6-1.4-.8-1.9c-.2-.4-.4-.4-.6-.4h-.5z"/>'
  }
})
