/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'live_chat': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M0 13c0 3.54 1.53 6.76 4 9.1V28l4.34-3.1c1.73.7 3.65 1.1 5.66 1.1 7.73 0 14-5.82 14-13S21.73 0 14 0 0 5.82 0 13zm17 0c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm-5 0c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm-5 0c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z"/>`
  }
})
