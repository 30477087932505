/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: `<path pid="0" _fill="#BAC5C9" d="M20 .98L19.02 0 10 9.02.98 0 0 .98 9.02 10 0 19.02l.98.98L10 10.98 19.02 20l.98-.98L10.98 10z"/>`
  }
})
