import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.prototype.$http = axios;

Vue.use(Vuex);

export default class TourListController {
  constructor() {
    this.cityPageUrl = process.env.CITY_PAGE_API;
    this.feedbackURL = process.env.FEEDBACK_API;
    this.accessToken = process.env.API_ACCESS_TOKEN;
    this.delay = 3000;
    this.microData = null;
    this.cityData = null;
    this.filters = null;
  }

  async getListContent(citySlug) {
    var self = this;
    const cityUrl = citySlug;
    const response = await axios({
      url: `${this.cityPageUrl}sites/walksofitaly/city/${cityUrl}?isVentrata=true`,
      headers: { Authorization: `Bearer ${this.accessToken}` }
    });

    if (response.status === 200) {
      // var tagsData = response.data.tags;
      var tourList = response.data.tourList;
      delete response.data.tourList;
      self.microData = response.data.microData;
      self.cityData = response.data;

      const tours = tourList.map(tourData => {
        return {
          url: tourData["url"],
          slug: tourData["slug"],
          flag: tourData["flag"],
          name: tourData["name"],
          image: tourData["image"],
          medal: tourData["medal"],
          eventId: tourData["eventId"],
          event_id: tourData["eventId"],
          citySlug: tourData["citySlug"],
          discount: tourData["discount"],

          // price: tourData['price'],
          // priceMap: {
          //   currencies: tourData["currencies"],
          //   anchorPrice: tourData["anchorPrice"]
          // },

          titleShort: tourData["titleShort"],
          listingText: tourData["listingText"],
          reviewStatus: tourData["reviewStatus"] || {
            feedbackAverage: 0,
            feedbackCount: 0
          },
          // review_status: tourData['reviewStatus']? { data: tourData['reviewStatus']} : { data: {feedbackAverage: 0, feedbackCount: 0}},
          duration: tourData["duration"],
          groupSize: tourData["groupSize"],
          tourFilterTags: tourData["tourFilterTags"],
          tourOrder: tourData["tourOrder"],
          bookingTypeId: tourData["bookingTypeId"],
          propertyId: tourData["propertyId"],
          promo: tourData["promo"],
        }
      });

      return {
        tours: tours,
        cityData: self.cityData,
      };
    } else {
      throw new Error(response.data?.error?.message);
    }
  }

  buildBreadCrumbs(data) {
    var items = [];
    for (var i = 0; i < data.urls.length; i++) {
      let name = "Take Walks";
      if (i > 0) {
        let path = data.urls[i].split("/");
        if (data.urls[i].match(/\/$/)) {
          name = path[path.length - 2];
        } else {
          name = path[path.length - 1];
        }
        name = name
          .replace(/-/g, " ")
          .toLowerCase()
          .split(" ")
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
      }
      items.push({
        "@type": "ListItem",
        position: i + 1,
        item: {
          "@id": data.urls[i],
          name: name
        }
      });
    }
    var breadCrumbs = JSON.stringify({
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: items
    });
    return breadCrumbs;
  }

  async getTour($sentry, cityUrl, tourSlug) {
    if (cityUrl.startsWith('_')) {
      return
    }
    console.log('getTour', cityUrl, tourSlug)
    const city = cityUrl.replace("-tours", "");
    const transaction = $sentry.startTransaction({
      name: `get tours with: ${city}-${tourSlug}`
    });
    const span = transaction?.startChild({ op: "get" });
    const response = await axios({
      url: this.cityPageUrl + `sites/walksofitaly/tour/${tourSlug}?isVentrata=true`,
      headers: {
        Authorization: "Bearer " + this.accessToken,
        ...$sentry.getCurrentHub().traceHeaders()
      }
    });
    if (span) {
      span.setData("response", response);
      span.finish();
    }
    transaction?.finish();
    if (response?.status == 200 && response?.data) {
      var tourData = response.data;
      var tour = {
        ventrataProduct: tourData.product,
        ventrataProductCode: tourData.ventrataCode,
        toursBookedTogetherCart: tourData.toursBookedTogetherCart,
        eventID: tourData["eventId"],
        citySlug: tourData["citySlug"],
        citySlugs: tourData["citySlugs"],
        tourSlug: tourSlug,
        cityName: tourData["cityName"],
        canonicalLink: tourData["canonicalLink"],
        shortTitle: tourData["shortTitle"],
        title: tourData["title"],
        duration: tourData["duration"],
        maxGroupSize: tourData["maxGroupSize"],
        intro: tourData["intro"],
        video: tourData["video"] ? tourData["video"] : "",
        sitesVisited: tourData["sitesVisited"],
        tourIncludes: tourData["tourIncludes"],
        descriptionTitle: tourData["descriptionTitle"],
        descriptionAuthor: tourData["descriptionAuthor"] ? tourData["descriptionAuthor"] : null,
        description: tourData["description"],
        faqs: tourData["faqs"]?.["data"],
        similarTours: tourData["similarTours"],
        gallery: tourData["gallery"],
        videoThumbnail: tourData["listingImage"],
        priceMap: tourData["priceMap"],
        metaTitle: tourData["tourPageMetaTitle"],
        metaDescription: tourData["tourPageMetaDescription"],
        // discount: tourData["discount"],
        // twAdditional: tourData["twAdditional"],
        // meetingPointMapUrl: tourData["meetingPointMapUrl"],
        highlights: tourData["highlights"],
        introTitle: tourData["introTitle"],
        tagPageUrl: tourData["tagPageUrl"],
        microData: tourData["microData"],
        countryName: tourData["countryName"],
        tourBanner: tourData["tourBanner"],
        tourImportantInfo: tourData["tourImportantInfo"],
        tourStartTime: tourData["tourStartTime"],
        anchorSdk: true,
        bookingTypeId: tourData["bookingTypeId"],
        propertyId: tourData["propertyId"],
        brandCard: tourData["brandCard"] ? tourData["brandCard"].data : null,
        perfectDay: tourData["perfectDay"] ? tourData["perfectDay"] : null,
        promo: tourData["promo"] ? tourData["promo"] : null,
        awards: tourData["awards"] ? tourData["awards"] : [],
      };
      tour = {
        ...tour,
        reviewStatus: tourData["reviewStatus"] || null,
        reviewsAverage: tourData["reviewStatus"]?.feedbackAverage || 0,
        reviewsCount: tourData["reviewStatus"]?.feedbackCount || 0,
        starsGroup: tourData["reviewStatus"]?.starsGroup,
      };
      return { tour };
    }
  }

  async getToursUnavailabilities(cityUrl) {
    try {
      const response = await axios.get(
        `${this.cityPageUrl}sites/walksofitaly/city/${cityUrl}/ventrata/unavailabilities`,
        { headers: { Authorization: "Bearer " + this.accessToken }}
      );
      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("getToursUnavailabilities err : ", err);
      return [];
    }
  }

  async getTourUnavailability (tourSlug, direct) {
    try {
      const response = await axios.get(
        `${this.cityPageUrl}sites/walksofitaly/tour/${tourSlug}/ventrata/unavailabilities${direct ? '/direct' : ''}`,
        { headers: { Authorization: "Bearer " + this.accessToken }}
      );

      return response.data
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`getTourUnavailability err : ${reqUrl}`, err)
      return false
    }
  }

  async getCityToursPrice ({ city, subUrl }) {
    try {
      const res = await axios.get(
        `${this.cityPageUrl}sites/walksofitaly/city/${city}/ventrata/price`,
        { headers: { Authorization: "Bearer " + this.accessToken }}
      );

      return res.data
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(`${city} - ${subUrl} getCityToursPrice err: `, err)
      return {}
    }
  }

  async getTourPrice ($axios, tourSlug) {
    $axios.setToken(this.accessToken, 'Bearer')
    try {
      const response = await $axios.get(
        this.cityPageUrl + `sites/walksofitaly/tour/${tourSlug}/ventrata/price`
      )
      return response.data
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('getTourPrice error : ', err)
      return {}
    }
  }

  async getTourMeetingPoint (tourSlug, $axios) {
    $axios.setToken(this.accessToken, 'Bearer')
    const response = await $axios.get(
      this.cityPageUrl + `sites/walksofitaly/tour/${tourSlug}/ventrata/meeting-point`
    )
    const tourMeetingPoint = response.data
    if (response.status !== 200) {
      throw new Error('Meeting Point not found')
    }

    return {
      tourMeetingPoint: {
        ...tourMeetingPoint
      }
    }
  }
}
