/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M0 25c0 13.81 11.19 25 25 25s25-11.19 25-25S38.81 0 25 0 0 11.19 0 25zm22-6l10 7-10 6V19z"/>`
  }
})
