/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 7,
    height: 4.03,
    viewBox: '0 0 7 4.03',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M0 0l3.5 4.03L7 0z"/>`
  }
})
