/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 33,
    height: 32,
    viewBox: '0 0 33 32',
    data: '<path pid="0" d="M16.116 30.013h-5.824c-5.37 0-7.765-2.395-7.765-7.765v-5.824c0-5.37 2.394-7.765 7.765-7.765h5.824c5.37 0 7.765 2.394 7.765 7.765v5.824c0 5.37-2.395 7.765-7.765 7.765zM10.292 10.6c-4.296 0-5.823 1.528-5.823 5.824v5.824c0 4.296 1.527 5.823 5.823 5.823h5.824c4.296 0 5.823-1.527 5.823-5.823v-5.824c0-4.296-1.527-5.823-5.823-5.823h-5.824z" _fill="#443C47"/><path pid="1" d="M24.398 18.365H22.91c-.53 0-.97-.44-.97-.97v-.97c0-4.297-1.528-5.825-5.824-5.825h-.97c-.531 0-.971-.44-.971-.97V8.142c0-4.116 1.837-5.953 5.953-5.953h4.27c4.116 0 5.953 1.837 5.953 5.953v4.27c0 4.116-1.837 5.953-5.953 5.953zm-.517-1.94h.517c3.041 0 4.012-.972 4.012-4.013v-4.27c0-3.042-.97-4.012-4.012-4.012h-4.27c-3.042 0-4.012.97-4.012 4.012v.517c5.37 0 7.765 2.394 7.765 7.765z" _fill="#443C47"/>'
  }
})
