/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'notes-for-visiting': {
    width: 36,
    height: 43,
    viewBox: '0 0 36 43',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M8 10v3h3v-3H8zm7 0v3h14v-3H15zm-7 7v3h3v-3H8zm7 0v3h14v-3H15zm-7 7v3h3v-3H8zm7 0v3h14v-3H15zm-7 7v3h3v-3H8zm7 0v3h14v-3H15zM1.98 0C.89 0 0 .89 0 1.99v39.02C0 42.11.89 43 1.98 43h32.04c1.09 0 1.98-.89 1.98-1.99V1.99C36 .89 35.11 0 34.02 0H1.98zm.79 1.51h30.46c.7 0 1.27.58 1.27 1.29v37.4c0 .71-.57 1.29-1.27 1.29H2.77c-.7 0-1.27-.58-1.27-1.29V2.8c0-.71.57-1.29 1.27-1.29z"/>`
  }
})
