/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'calendar_active': {
    width: 19,
    height: 20,
    viewBox: '0 0 19 20',
    data: `<path pid="0" fill-rule="evenodd" _fill="#039BE4" d="M5.18 9.79a.425.425 0 1 0 0 .85.425.425 0 1 0 0-.85zm0 1.7c-.71 0-1.29-.57-1.29-1.28 0-.7.58-1.27 1.29-1.27.72 0 1.3.57 1.3 1.27 0 .71-.58 1.28-1.3 1.28zm0 2.98a.425.425 0 1 0 0 .85.425.425 0 1 0 0-.85zm0 1.7c-.71 0-1.29-.57-1.29-1.28 0-.7.58-1.27 1.29-1.27.72 0 1.3.57 1.3 1.27 0 .71-.58 1.28-1.3 1.28zM9.5 9.79a.425.425 0 1 0 0 .85.425.425 0 1 0 0-.85zm0 1.7c-.72 0-1.3-.57-1.3-1.28 0-.7.58-1.27 1.3-1.27s1.3.57 1.3 1.27c0 .71-.58 1.28-1.3 1.28zm0 2.98a.425.425 0 1 0 0 .85.425.425 0 1 0 0-.85zm0 1.7c-.72 0-1.3-.57-1.3-1.28 0-.7.58-1.27 1.3-1.27s1.3.57 1.3 1.27c0 .71-.58 1.28-1.3 1.28zm4.32-6.38a.425.425 0 1 0 0 .85.425.425 0 1 0 0-.85zm0 1.7c-.72 0-1.3-.57-1.3-1.28 0-.7.58-1.27 1.3-1.27.71 0 1.29.57 1.29 1.27 0 .71-.58 1.28-1.29 1.28zm0 2.98a.425.425 0 1 0 0 .85.425.425 0 1 0 0-.85zm0 1.7c-.72 0-1.3-.57-1.3-1.28 0-.7.58-1.27 1.3-1.27.71 0 1.29.57 1.29 1.27 0 .71-.58 1.28-1.29 1.28zm4.32-10.21H.86V18.3c0 .47.39.85.87.85h15.54c.48 0 .87-.38.87-.85V5.96zm0-2.98a.86.86 0 0 0-.87-.85h-2.16c0 .7-.58 1.27-1.29 1.27-.72 0-1.3-.57-1.3-1.27H10.8c0 .7-.58 1.27-1.3 1.27s-1.3-.57-1.3-1.27H6.48c0 .7-.58 1.27-1.3 1.27-.71 0-1.29-.57-1.29-1.27H1.73a.86.86 0 0 0-.87.85v2.13h17.28V2.98zm-3.89-1.7c0-.24-.19-.43-.43-.43s-.43.19-.43.43v.85c0 .23.19.42.43.42s.43-.19.43-.42v-.85zm-4.32 0c0-.24-.19-.43-.43-.43s-.43.19-.43.43v.85c0 .23.19.42.43.42s.43-.19.43-.42v-.85zm-4.32 0c0-.24-.19-.43-.43-.43s-.43.19-.43.43v.85c0 .23.19.42.43.42s.43-.19.43-.42v-.85zM17.27 20H1.73C.77 20 0 19.24 0 18.3V2.98c0-.94.77-1.7 1.73-1.7h2.16C3.89.57 4.47 0 5.18 0c.72 0 1.3.57 1.3 1.28H8.2C8.2.57 8.78 0 9.5 0s1.3.57 1.3 1.28h1.72c0-.71.58-1.28 1.3-1.28.71 0 1.29.57 1.29 1.28h2.16c.96 0 1.73.76 1.73 1.7V18.3c0 .94-.77 1.7-1.73 1.7z"/>`
  }
})
