/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'star_top_active': {
    width: 25.23,
    height: 23,
    viewBox: '0 0 25.23 23',
    data: `<path pid="0" _fill="#FFB446" d="M12.61 0l3.41 8.22 9.21.56-7.1 5.65L20.4 23l-7.79-4.72L4.82 23l2.28-8.57L0 8.78l9.21-.56z"/>`
  }
})
