/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'upcoming-tours': {
    width: 37,
    height: 40,
    viewBox: '0 0 37 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M10.53 6.03V0H8.89v6.03h1.64zm17.58 0V0h-1.64v6.03h1.64zm-4.77-2.18V2.18h-9.89v1.67h9.89zM20 29l1 2 4-5-4-5-1 2 2 2H11v2h11l-2 2zm15.36-17.77H1.64V6.36c0-1.54.5-2.43.65-2.51h4.48V2.18H2.29C.79 2.18 0 4.28 0 6.36v29.46C0 38.12 1.84 40 4.09 40h28.82c2.25 0 4.09-1.88 4.09-4.18V6.36c0-2.08-.79-4.18-2.29-4.18h-4.48v1.67l4.43-.01c.2.09.7.98.7 2.52v4.87zM1.64 12.9h33.72v22.92c0 1.38-1.1 2.51-2.45 2.51H4.09c-1.35 0-2.45-1.13-2.45-2.51V12.9z"/>`
  }
})
