/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'point-icon3': {
    width: 72,
    height: 72,
    viewBox: '-12 -12 72 72',
    data: '<path pid="0" d="M9 17.916H3c-1.657 0-3 1.343-3 2.915v20.916c0 1.656 1.343 2.916 3 2.916h6c1.657 0 3-1.344 3-2.916V20.916c0-1.575-1.34-3-3-3zm39 3.365c0-3.458-2.817-6.273-6.278-6.273H32.36c.893-2.342 1.39-4.367 1.39-5.558 0-3.169-2.456-6.448-6.568-6.448-4.37 0-5.565 3.041-6.526 5.484C17.688 16.033 15 14.71 15 17.25a2.251 2.251 0 003.655 1.756c7.197-5.753 5.435-11.503 8.527-11.503 1.507 0 2.068 1.195 2.068 1.949 0 .694-.712 3.708-2.395 6.712-.192.341-.288.72-.288 1.098 0 1.305 1.072 2.166 2.25 2.166h12.9c.986.082 1.783.88 1.783 1.854 0 .92-.728 1.69-1.657 1.754a2.25 2.25 0 00-2.096 2.245c0 1.45 1.068 1.495 1.068 2.71 0 2.378-3.284 1.157-3.284 3.952 0 1.051.6 1.221.6 2.086 0 2.124-2.792 1.29-2.792 3.81 0 .423.104.558.104.886a1.78 1.78 0 01-1.778 1.777H28.74c-2.402 0-4.783-.794-6.703-2.233L18.6 35.69a2.235 2.235 0 00-1.348-.45c-1.298 0-2.253 1.066-2.253 2.253 0 .683.31 1.352.9 1.793l3.438 2.58A15.782 15.782 0 0028.744 45h4.924a6.285 6.285 0 006.263-5.831 6.23 6.23 0 002.643-5.996 6.27 6.27 0 002.745-5.182c0-.498-.06-.988-.176-1.466C46.866 25.322 48 23.456 48 21.28z" _fill="#5E2013"/>'
  }
})
