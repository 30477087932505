/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'sell_out': {
    width: 31,
    height: 32,
    viewBox: '0 0 31 32',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M24 30.4c-.77 0-1.4-.63-1.4-1.4s.63-1.4 1.4-1.4 1.4.63 1.4 1.4-.63 1.4-1.4 1.4zm0-4.4c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm1.86-4.27H12.77L9.8 11.61h19.03l-2.97 10.12zM31 10.67v-.07l-.01-.03v-.06l-.01-.03-.01-.04-.01-.05-.01-.02a.906.906 0 0 0-.11-.22l-.04-.05-.01-.02a1.07 1.07 0 0 0-.19-.18l-.05-.03-.04-.02-.03-.02-.06-.02-.02-.01-.06-.02-.03-.01-.06-.02h-.05l-.04-.01H9.25l-.9-3.07-.02-.07-.01-.02-.02-.06-.02-.03-.02-.03-.03-.05a.837.837 0 0 0-.18-.2l-.02-.01-.05-.04a.906.906 0 0 0-.22-.11l-.07-.02-.04-.01-.03-.01h-.06L7.54 6H.93a.935.935 0 0 0 0 1.87h5.84l4.41 15.05.02.05.01.04.02.04.02.04.01.03.03.05.02.02.04.06.06.07.06.06.02.01.05.04.03.01.04.03.03.02.05.02.04.02.06.02.03.01.05.01.03.01.06.01h.02l.09.01h14.48l.09-.01h.02l.07-.01.03-.01.05-.01.03-.01.06-.02.04-.02.05-.02.03-.02.04-.03.03-.01.04-.04.02-.01.06-.05.06-.07.05-.07.01-.02.03-.05.02-.03.02-.04.02-.04.01-.04.02-.04 3.51-11.98v-.01l.01-.03.01-.06.01-.05.01-.04V10.67zM14 30.4c-.77 0-1.4-.63-1.4-1.4s.63-1.4 1.4-1.4 1.4.63 1.4 1.4-.63 1.4-1.4 1.4zm0-4.4c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm6-19V4h3l-4-4-4 4h3v3h2z"/>`
  }
})
