/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'problems-while-traveling': {
    width: 37,
    height: 40,
    viewBox: '0 0 37 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M16.98 10.61H4.38L1.95 8.16l2.43-2.45h12.6v4.9zm0-6.53H4.04a.82.82 0 0 0-.57.24L.24 7.59c-.32.32-.32.83 0 1.15l3.23 3.27c.15.15.36.23.57.23h12.94v4.09H8.89c-.21 0-.42.08-.57.24l-3.23 3.26a.82.82 0 0 0 0 1.16l3.23 3.26c.15.15.36.24.57.24h8.09v7.78c0 .98-.42 1.89-1.16 2.5a5.708 5.708 0 0 0-2.08 4.41c0 .45.37.82.81.82h9.71c.44 0 .8-.37.8-.82 0-1.71-.75-3.32-2.07-4.41a3.216 3.216 0 0 1-1.16-2.5V20.41h11.13c.21 0 .42-.09.57-.24l3.23-3.27c.32-.32.32-.83 0-1.15l-3.23-3.27a.82.82 0 0 0-.57-.24H21.83V2.45C21.83 1.1 20.74 0 19.4 0c-1.33 0-2.42 1.1-2.42 2.45v1.63zm0 18.78H9.23L6.8 20.41l2.43-2.45h7.75v4.9zm4.85-8.98h10.79l2.43 2.45-2.43 2.45H21.83v-4.9zm-6.39 24.49c.19-.91.67-1.74 1.4-2.34a4.839 4.839 0 0 0 1.76-3.76V2.45c0-.45.36-.82.8-.82.45 0 .81.37.81.82v29.82c0 1.47.64 2.84 1.75 3.76.73.6 1.22 1.43 1.41 2.34h-7.93z"/>`
  }
})
