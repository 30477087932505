/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'colosseum-tours': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M38.52 37.78c0 .41-.33.74-.74.74H36.3v-6.67c0-2.86-2-5.18-4.45-5.18s-4.44 2.32-4.44 5.18v6.67h-2.97v-6.67c0-2.86-1.99-5.18-4.44-5.18s-4.44 2.32-4.44 5.18v6.67h-2.97v-6.67c0-2.86-1.99-5.18-4.44-5.18S3.7 28.99 3.7 31.85v6.67H2.22c-.41 0-.74-.33-.74-.74V24.44c0-.4.33-.74.74-.74h35.56c.41 0 .74.34.74.74v13.34zM22.96 18.52h-5.92v-5.19c0-2.04 1.33-3.7 2.96-3.7s2.96 1.66 2.96 3.7v5.19zm-11.85 0H5.19v-5.19c0-2.04 1.32-3.7 2.96-3.7 1.63 0 2.96 1.66 2.96 3.7v5.19zm27.41 2.22v.74c0 .41-.33.74-.74.74H2.22c-.41 0-.74-.33-.74-.74v-.74c0-.41.33-.74.74-.74h35.56c.41 0 .74.33.74.74zm-.36-2.33c-.11.07-.24.11-.38.11h-4.61L23.33.48a.826.826 0 0 0-1.04-.36L22.23 0H2.22C1 0 0 1 0 2.22v.74c0 .57.22 1.09.58 1.48-.36.4-.58.92-.58 1.49v11.85c0 .57.22 1.09.58 1.48-.36.39-.58.91-.58 1.48v.74c0 .57.22 1.09.58 1.48-.36.4-.58.91-.58 1.48v13.34C0 39 1 40 2.22 40h2.22c.41 0 .75-.33.75-.74v-7.41c0-2.04 1.32-3.7 2.96-3.7 1.63 0 2.96 1.66 2.96 3.7v7.41c0 .41.33.74.74.74h4.45c.41 0 .74-.33.74-.74v-7.41c0-2.04 1.33-3.7 2.96-3.7s2.96 1.66 2.96 3.7v7.41c0 .41.33.74.74.74h4.45c.41 0 .74-.33.74-.74v-7.41c0-2.04 1.33-3.7 2.96-3.7 1.64 0 2.96 1.66 2.96 3.7v7.41c0 .41.34.74.75.74h2.22C39 40 40 39 40 37.78V24.44c0-.57-.22-1.08-.58-1.48.36-.39.58-.91.58-1.48v-.74c0-.57-.22-1.09-.58-1.48.22-.24.39-.53.48-.85h-1.74zm-6.66.11H24.44v-5.19c0-2.86-1.99-5.18-4.44-5.18s-4.44 2.32-4.44 5.18v5.19h-2.97v-5.19c0-2.86-1.99-5.18-4.44-5.18S3.7 10.47 3.7 13.33v5.19H2.22c-.41 0-.74-.33-.74-.74V5.93c0-.41.33-.74.74-.74l22.02-.01 7.26 13.34zM23.44 3.7H2.22c-.41 0-.74-.33-.74-.74v-.74c0-.41.33-.74.74-.74h20l1.22 2.22z"/>`
  }
})
