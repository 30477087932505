/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'point-icon2': {
    width: 72,
    height: 72,
    viewBox: '0 0 72 72',
    data: '<path pid="1" d="M36 12c.985 0 1.847.623 2.147 1.539l4.753 14.66h14.85a2.24 2.24 0 012.129 1.491c.309.893.009 1.878-.75 2.458l-12.263 9.37 4.781 14.727c.3.92-.047 1.933-.862 2.485a2.27 2.27 0 01-2.663-.074L36 49.406l-12.122 9.25a2.275 2.275 0 01-2.665.074 2.193 2.193 0 01-.857-2.485l4.778-14.727-12.265-9.37a2.18 2.18 0 01-.747-2.458 2.246 2.246 0 012.128-1.49h14.86l4.743-14.661A2.257 2.257 0 0136 12zm0 9.499l-3.103 9.581a2.253 2.253 0 01-2.147 1.538h-9.942l8.32 6.36a2.18 2.18 0 01.769 2.411l-3.056 9.416 7.781-5.937a2.258 2.258 0 012.756 0l7.782 5.937-3.057-9.416a2.18 2.18 0 01.77-2.411l8.324-6.36H41.25a2.253 2.253 0 01-2.147-1.538L36 21.5z" _fill="#5E2013"/>'
  }
})
