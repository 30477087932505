/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'arrow_left': {
    width: 17.005,
    height: 31.998,
    viewBox: '0 0 17.005 31.998',
    data: `<path pid="0" _fill="#B8C2C8" d="M16.743.248a.888.888 0 0 0-1.23 0L.263 15.388a.84.84 0 0 0 0 1.22l15.25 15.14c.17.17.39.25.62.25.22 0 .44-.08.61-.25.35-.34.35-.88 0-1.21l-14.63-14.54 14.63-14.54a.83.83 0 0 0 0-1.21z"/>`
  }
})
