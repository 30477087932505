/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'rome-food-tours': {
    width: 39,
    height: 39,
    viewBox: '0 0 39 39',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M14.76 11.9c-.36-2.7-1.11-7.19-1.4-8.92H3.31c-.29 1.73-1.03 6.22-1.4 8.92-.15 1.09-.2 2.1-.21 3.05h13.27c0-.95-.06-1.96-.21-3.05zM3 20.8c.71 1.18 2.51 3.12 5.34 5.77 2.82-2.65 4.62-4.59 5.34-5.77.66-1.1 1.06-2.5 1.22-4.14H1.78c.16 1.64.55 3.04 1.22 4.14zm12.11.9c-1.24 2.04-4.76 5.32-5.92 6.4v9.1h5.93c.47 0 .84.38.84.85s-.37.86-.84.86H1.59a.85.85 0 0 1-.84-.86c0-.47.37-.85.84-.85h5.92v-9.08l-.04-.03c-1.15-1.08-4.67-4.36-5.91-6.39C.67 20.23.21 18.35.08 16.16a.817.817 0 0 1-.08-.35c0-.09.03-.17.05-.25-.03-1.21.01-2.49.19-3.89.46-3.41 1.52-9.63 1.53-9.7.07-.41.42-.71.83-.71h11.47c.41 0 .76.3.83.71.01.07 1.07 6.29 1.53 9.7.2 1.48.27 2.84.22 4.1l.01.04-.01.04c-.09 2.35-.6 4.31-1.54 5.85zM27.7 11.53h-5.05v.9c0 1.46 1.1 2.61 2.51 2.63 1.4-.02 2.54-1.2 2.54-2.63v-.9zm-1.68 5.14v21.38c0 .47-.38.86-.84.86a.85.85 0 0 1-.84-.86V16.68c-1.94-.4-3.37-2.12-3.37-4.25v-1.75l.01-.03-.01-.03V3.77c0-.47.38-.85.84-.85.47 0 .84.38.84.85v6.05h1.69V3.77c0-.47.37-.85.84-.85.46 0 .84.38.84.85v6.05h1.68V3.77c0-.47.38-.85.84-.85.47 0 .85.38.85.85v6.85l-.01.03.01.03v1.75c0 2.07-1.45 3.82-3.37 4.24zM36.18 3.86c-.15-2.15-.78-2.15-1.16-2.15-.17 0-.21.03-.21.03-.22.16-.33 1.08-.38 2.51v12.18c0 1.19.48 1.77 1.45 1.77h.21c.33 0 .84 0 1.03-.12.18-.11.2-.63.2-.84L36.18 3.86zM38 19.54c-.6.37-1.33.37-1.91.37h-.11c-.54 0-1.07-.12-1.55-.35v18.58c0 .48-.37.86-.84.86a.85.85 0 0 1-.84-.86V16.43c0-2.38-.01-4.4-.02-6.13-.02-2.79-.03-4.75.02-6.17v-.36c0-.03.02-.06.02-.09.09-1.8.32-2.62.84-3.15.35-.36.81-.53 1.41-.53 2.58 0 2.78 2.81 2.84 3.73L39 17.17c0 .48 0 1.74-1 2.37z"/>`
  }
})
