/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filters': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.75 4.5h16.34a3.5 3.5 0 100-1.5H.75a.75.75 0 000 1.5zM20.5 1.75a2 2 0 11-2 2 2 2 0 012-2zm2.75 17.75H9.46a3.5 3.5 0 00-6.83 0H.75a.75.75 0 000 1.5h1.88a3.5 3.5 0 006.83 0h13.79a.75.75 0 000-1.5zm-17.2 2.75a2 2 0 112-2 2 2 0 01-2 2zM23.25 11h-7.84a3.49 3.49 0 00-6.82 0H.75a.75.75 0 000 1.5h7.84a3.49 3.49 0 006.82 0h7.84a.75.75 0 000-1.5zM12 13.75a2 2 0 112-2 2 2 0 01-2 2z"/>'
  }
})
