/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'remove_tour': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: `<path pid="0" fill-rule="evenodd" _fill="#AF3655" d="M13 0C5.83 0 0 5.83 0 13s5.83 13 13 13 13-5.83 13-13S20.17 0 13 0zm5.73 18.73c-.35.34-.91.34-1.25 0l-4.02-4.02-4.02 4.02c-.34.34-.9.34-1.25 0a.893.893 0 0 1 0-1.25l4.02-4.02-4.02-4.02a.893.893 0 0 1 0-1.25c.35-.34.91-.34 1.25 0l4.02 4.02 4.02-4.02c.34-.34.9-.34 1.25 0 .34.35.34.91 0 1.25l-4.02 4.02 4.02 4.02c.34.34.34.9 0 1.25z"/>`
  }
})
