/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'verified-reviews': {
    width: 39,
    height: 40,
    viewBox: '0 0 39 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M30.69 27.75h2.62a3.935 3.935 0 1 1 0 7.87h-1.75l-2.18 1.32v-1.56c-1.53-.54-2.63-1.98-2.63-3.69 0-2.18 1.76-3.94 3.94-3.94zm-3 8.75h-.06V40l3.93-2.63h1.75c3.14 0 5.69-2.54 5.69-5.68S36.45 26 33.31 26h-2.62C27.55 26 25 28.55 25 31.69c0 2.03 1.08 3.81 2.69 4.81zM13.87 17.34c-.63-1.19-.5-1.65-.46-1.74.03-.07.08-.1.07-.1.46-.09.79-.49.76-.95 0-.02-.07-1.47.28-2.8.01-.06.02-.11.02-.17.01-.11.27-2.85 4.65-2.85 4.26 0 4.62 2.56 4.65 2.85 0 .05.01.11.02.17.35 1.32.28 2.78.28 2.8-.02.46.26.85.72.94.03.01.08.04.12.11.03.09.16.55-.47 1.74l-.02.01c-.28.18-.58.36-.69.74-.27.97-2.13 4.58-4.61 4.58s-4.33-3.61-4.61-4.59c-.11-.37-.39-.55-.69-.73l-.02-.01zm5.32 7.17c.99 0 1.87-.32 2.63-.81.37.29.96.7 1.77 1.1.13.07.27.1.41.1a.918.918 0 0 0 .42-1.74c-.49-.24-.87-.48-1.15-.69 1.17-1.27 1.92-2.81 2.21-3.57.23-.14.42-.26.56-.5.83-1.49 1.04-2.69.61-3.59-.16-.34-.4-.6-.65-.78 0-.63-.06-1.67-.33-2.7-.1-.71-.92-4.44-6.48-4.44-5.54 0-6.37 3.74-6.48 4.44-.27 1.02-.32 2.07-.33 2.7-.25.18-.49.44-.65.78-.43.9-.22 2.1.61 3.59.14.24.33.36.56.5.29.76 1.04 2.3 2.21 3.57-.5.37-1.36.88-2.53 1.25-3.77 1.2-6.05 3.49-6.68 6.68a17.283 17.283 0 0 1-4.04-11.11c0-9.62 7.91-17.45 17.64-17.45s17.64 7.83 17.64 17.45c0 1.13-.11 2.27-.33 3.37-.09.5.23.98.74 1.08.51.1.99-.23 1.09-.73.24-1.22.36-2.47.36-3.72C39 8.65 30.25 0 19.5 0S0 8.65 0 19.29s8.75 19.29 19.5 19.29c.51 0 .93-.41.93-.91 0-.51-.42-.92-.93-.92-4.59 0-8.78-1.75-11.93-4.6.07-3.33 1.89-5.51 5.58-6.68 1.68-.53 2.83-1.31 3.41-1.77.77.49 1.64.81 2.63.81z"/>`
  }
})
