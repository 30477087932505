/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'most-popular-milan-tours': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: `<path pid="0" _fill="#58B69E" d="M19.997 37.706a1.25 1.25 0 0 1-.891-.367L4.5 22.733l-.106-.111C-.456 17.647-.804 9.936 3.679 5.454c4.235-4.235 11.337-4.175 16.322-.045 4.982-4.13 12.088-4.185 16.318.045 4.482 4.483 4.149 12.198-.746 17.194l-.1.111-14.586 14.58a1.249 1.249 0 0 1-.89.367zM11.219 4.207a8.85 8.85 0 0 0-6.338 2.524c-3.667 3.666-3.295 10.02.796 14.199l.095.1 14.226 14.235 14.241-14.239.091-.096c4.09-4.189 4.445-10.544.79-14.194-3.657-3.65-10.021-3.3-14.195.785a1.37 1.37 0 0 1-1.849 0 11.338 11.338 0 0 0-7.857-3.314z"/>`
  }
})
