/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'twitter': {
    width: 23.2,
    height: 19.55,
    viewBox: '0 0 23.2 19.55',
    data: `<path pid="0" _fill="#FFF" d="M23.2 2.31c-.85.4-1.77.66-2.73.78A4.91 4.91 0 0 0 22.56.36c-.92.57-1.94.98-3.02 1.2A4.689 4.689 0 0 0 16.06 0c-2.63 0-4.76 2.21-4.76 4.94 0 .38.04.76.13 1.12C7.47 5.85 3.96 3.89 1.61.9 1.2 1.63.97 2.48.97 3.38c0 1.72.84 3.23 2.12 4.11-.78-.02-1.52-.25-2.16-.61v.06c0 2.39 1.64 4.38 3.82 4.84-.4.11-.82.17-1.26.17-.3 0-.6-.03-.89-.09.6 1.96 2.36 3.39 4.45 3.43a9.382 9.382 0 0 1-5.92 2.11c-.38 0-.76-.02-1.13-.07 2.1 1.4 4.61 2.22 7.3 2.22 8.75 0 13.54-7.52 13.54-14.04l-.01-.64c.93-.7 1.73-1.57 2.37-2.56z"/>`
  }
})
