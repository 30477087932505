/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 36.19,
    height: 33,
    viewBox: '0 0 36.19 33',
    data: `<path pid="0" _fill="#D3D3D3" d="M18.09 0l4.89 11.79 13.21.81-10.18 8.1L29.27 33l-11.18-6.77L6.92 33l3.26-12.3L0 12.6l13.21-.81z"/>`
  }
})
