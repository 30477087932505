/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'checkmark': {
    width: 14,
    height: 12,
    viewBox: '0 0 14 12',
    data: `<path pid="0" fill-rule="evenodd" _fill="#3BA1E8" d="M14 1.14L5.7 12 0 6.97l1.05-1.14L5.7 9.71 12.95 0z"/>`
  }
})
