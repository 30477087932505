/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'star_transparent': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M14.17 8.89l-2.66-4.77v.07L8.9 8.92 3.69 10.1l3.53 3.88-.74 5.68 5.03-2.45 4.82 2.18-.49-5.39 3.65-3.97-5.32-1.14zm3.56 5.92l.99 8.19-7.22-3.51L4.28 23l.99-8.19L0 8.79l7.65-1.56L11.5 0l3.85 7.23L23 8.79l-5.27 6.02z"/>`
  }
})
