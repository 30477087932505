/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M13 8h-3V5H8v3H5v2h3v3h2v-3h3V8zM0 9a9 9 0 1 0 18.001-.001A9 9 0 0 0 0 9zm2 0c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7-7-3.13-7-7z"/>`
  }
})
