/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'exclusive': {
    width: 30,
    height: 24,
    viewBox: '0 0 30 24',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M25.61 24H4.39c-1.42 0-2.57-.97-2.57-2.16v-.29c0-1.2 1.15-2.17 2.57-2.17h21.22c1.42 0 2.57.97 2.57 2.17v.29c0 1.19-1.15 2.16-2.57 2.16zM4.39 20.83c-.48 0-.86.32-.86.72v.29c0 .39.38.72.86.72h21.22c.48 0 .86-.33.86-.72v-.29c0-.4-.38-.72-.86-.72H4.39zm20.56.27h-.09a.865.865 0 0 1-.74-.96l2.14-13.53c.05-.48.46-.82.92-.77.45.05.78.48.73.96l-2.14 13.53c-.05.44-.41.77-.82.77zm-5.71-9.2c-.07 0-.13-.01-.2-.03-.22-.05-.41-1.13-.52-1.34l-3.96-6.54a.883.883 0 0 1 .31-1.18c.4-.24.9-.09 1.13.33l3.52 5.74 5.22-3.85c.39-.26.9-.14 1.15.26s.14.94-.25 1.2l-5.95 5.27c-.14.09-.29.14-.45.14zm-8.62 0c-.16 0-.32-.05-.46-.14L4.25 6.48a.897.897 0 0 1-.24-1.21c.25-.4.76-.51 1.14-.25l5.19 3.86 3.56-5.75c.22-.41.73-.56 1.13-.32.39.24.53.77.3 1.19l-4 6.54c-.11.21-.3 1.28-.52 1.33-.06.02-.13.03-.19.03zm-5.24 9.2c-.4 0-.76-.31-.82-.75L2.14 6.75c-.06-.48.25-.92.71-.98.45-.06.87.27.93.74l2.43 13.61c.06.47-.26.91-.71.97l-.12.01zM15 4.62c-1.25 0-2.27-1.04-2.27-2.31C12.73 1.03 13.75 0 15 0s2.27 1.03 2.27 2.31c0 1.27-1.02 2.31-2.27 2.31zm0-3.26c-.52 0-.94.42-.94.95 0 .52.42.95.94.95s.94-.43.94-.95c0-.53-.42-.95-.94-.95zm12.73 5.1c-1.26 0-2.28-1.03-2.28-2.31 0-1.27 1.02-2.3 2.28-2.3 1.25 0 2.27 1.03 2.27 2.3 0 1.28-1.02 2.31-2.27 2.31zm0-3.26c-.52 0-.94.43-.94.95 0 .53.42.95.94.95.51 0 .93-.42.93-.95 0-.52-.42-.95-.93-.95zM2.27 6.46C1.02 6.46 0 5.43 0 4.15c0-1.27 1.02-2.3 2.27-2.3a2.29 2.29 0 0 1 2.28 2.3c0 1.28-1.02 2.31-2.28 2.31zm0-3.26c-.51 0-.93.43-.93.95 0 .53.42.95.93.95.52 0 .94-.42.94-.95 0-.52-.42-.95-.94-.95z"/>`
  }
})
