/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'full-star': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.45 8.25h7.425a1.125 1.125 0 01.689 2.01l-6.131 4.773 2.39 7.5a1.123 1.123 0 01-1.762 1.228l-6.06-4.71-6.062 4.71a1.12 1.12 0 01-1.333.038 1.128 1.128 0 01-.428-1.266l2.39-7.5L.433 10.26a1.123 1.123 0 01.691-2.01h7.43L10.927.783a1.126 1.126 0 012.147 0L15.45 8.25z" _fill="#A08F56"/>'
  }
})
