/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'uffizi-gallery-tours': {
    width: 39.999,
    height: 34,
    viewBox: '0 0 39.999 34',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M38.668 32.65H1.289v-1.8H38.619v1.8h.049zm-32.23-4.23h4.71v1.17h-4.71v-1.17zm1.201-14.57h2.35v13.27h-2.35V13.85zm3.509-2.48v1.17h-4.71v-1.17h4.71zM3.999 8.99h31.96v1.08H3.999V8.99zm15.98-7.61l15.89 6.3H4.088l15.891-6.3zm13.45 11.16h-4.66v-1.17h4.66v1.17zm-1.151 14.58h-2.36V13.85h2.36v13.27zm-3.509 1.3h4.71v1.17h-4.71v-1.17zm-11.141 0h4.66v1.17h-4.66v-1.17zm4.661-15.88h-4.71v-1.17h4.66v1.17h.05zm-1.15 14.58h-2.36V13.85h2.36v13.27zm-8.701 2.47v-1.8c0-.36-.27-.67-.67-.67h-.53V13.85h.53c.35 0 .67-.27.67-.68v-1.8h3.9v1.8c0 .36.27.68.67.68h.53v13.27h-.53c-.36 0-.67.31-.67.67v1.8h-3.9zm11.141 0v-1.8c0-.36-.31-.67-.67-.67h-.53V13.85h.53c.36 0 .67-.27.67-.68v-1.8h3.9v1.8c0 .36.31.68.67.68h.53v13.27h-.53c-.36 0-.67.31-.67.67v1.8h-3.9zm15.71 0h-4.57v-1.8c0-.36-.31-.67-.67-.67h-.53V13.85h.53c.36 0 .67-.27.67-.68v-1.8h1.86c.36 0 .67-.27.67-.67V8.94h2.04c.31 0 .58-.22.62-.54.05-.31-.09-.63-.4-.72L20.199.03a1.01 1.01 0 0 0-.49 0L.409 7.68c-.27.14-.45.41-.4.72.04.32.31.54.62.54h2.04v1.76c0 .36.27.67.67.67h1.82v1.8c0 .36.26.68.66.68h.53v13.27h-.53c-.35 0-.66.31-.66.67v1.8H.669c-.4 0-.66.27-.66.63v3.11c0 .36.26.67.66.67h38.66c.36 0 .67-.31.67-.67v-3.11a.722.722 0 0 0-.71-.63z"/>`
  }
})
