/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'collapse': {
    width: 26,
    height: 26,
    viewBox: '0 0 32 32',
    data: `<path pid="0" fill-rule="evenodd" _fill="#C4CAD2" d="M16.38 9.99c-.21-.2-.54-.2-.75 0l-8.3 8.31c-.21.21-.21.54 0 .75.2.21.54.21.75 0l7.93-7.92 7.921 7.92c.199.21.539.21.75 0a.539.539 0 0 0 0-.76l-8.301-8.3zM16 30.931C7.75 30.931 1.07 24.25 1.07 16S7.75 1.07 16 1.07 30.931 7.75 30.931 16 24.25 30.931 16 30.931zM16 0C7.16 0 0 7.16 0 16s7.16 16 16 16 16-7.16 16-16S24.84 0 16 0z"/>`
  }
})
