/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pinterest': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M12 .74C5.506.74.24 6.006.24 12.5S5.506 24.26 12 24.26s11.76-5.266 11.76-11.76S18.494.74 12 .74zm7.13 18.89A10.033 10.033 0 0112 22.58c-.85 0-1.685-.106-2.489-.31.147-.33.274-.672.367-1.012.147-.526.83-3.236.83-3.236.411.778 1.602 1.464 2.874 1.464 3.78 0 6.348-3.444 6.348-8.059 0-3.487-2.957-6.737-7.445-6.737-5.587 0-8.405 4.006-8.405 7.344 0 2.021.766 3.821 2.41 4.493.268.11.508.005.588-.295.052-.204.184-.73.242-.946.08-.295.048-.398-.168-.655-.473-.561-.778-1.281-.778-2.304 0-2.971 2.22-5.633 5.792-5.633 3.156 0 4.893 1.928 4.893 4.505 0 3.391-1.502 6.252-3.727 6.252-1.229 0-2.153-1.017-1.858-2.263.353-1.49 1.04-3.098 1.04-4.174 0-.96-.514-1.764-1.587-1.764-1.26 0-2.268 1.304-2.268 3.046 0 1.109.38 1.858.38 1.858l-1.513 6.41a8.171 8.171 0 00-.15.883 10.092 10.092 0 01-2.49-1.826A9.951 9.951 0 011.92 12.5c0-2.693 1.051-5.225 2.95-7.13A10.043 10.043 0 0112 2.42c2.693 0 5.225 1.051 7.13 2.95a10.033 10.033 0 012.95 7.13c0 2.693-1.049 5.225-2.95 7.13z" _fill="#443C47"/>'
  }
})
