/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'circle-close': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: `<path pid="0" d="M84.815 43.399a1.998 1.998 0 0 0-2.828 0L64.032 61.356 46.077 43.399a2 2 0 1 0-2.828 2.828l17.955 17.957-17.955 17.957a2 2 0 1 0 2.828 2.828l17.955-17.956 17.955 17.956c.391.39.902.585 1.414.585s1.023-.195 1.414-.585a2 2 0 0 0 0-2.828L66.86 64.184l17.955-17.957a1.998 1.998 0 0 0 0-2.828zM64.032 14.054c-27.642 0-50.129 22.487-50.129 50.127.002 27.643 22.491 50.131 50.133 50.131 27.639 0 50.125-22.489 50.125-50.131 0-27.64-22.487-50.127-50.129-50.127zm.004 96.259h-.002c-25.435 0-46.129-20.695-46.131-46.131 0-25.435 20.693-46.127 46.129-46.127s46.129 20.693 46.129 46.127c0 25.435-20.691 46.131-46.125 46.131z"/>`
  }
})
