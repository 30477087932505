/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'faq-new': {
    width: 49,
    height: 49,
    viewBox: '0 0 49 49',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M49 31.08c0-6.02-3.46-11.15-8.31-12.17-.42-.12-.78.26-.88.77-.1.5.21.95.62 1.07 4.08.95 7.02 5.32 7.02 10.39 0 4.56-2.37 8.55-5.89 10.01-.31.12-.51.5-.51.88v4.56l-3.77-4.62c-.16-.13-.36-.25-.57-.25h-6.35c-3.25 0-6.2-2.22-7.69-5.83-.21-.51-.68-.7-1.04-.44-.41.25-.57.82-.36 1.26 1.76 4.25 5.22 6.84 9.09 6.84h6.04l4.08 5.01c.26.31.57.44.88.44.15 0 .31-.06.46-.13.47-.25.78-.76.78-1.39v-4.81c3.82-1.9 6.4-6.46 6.4-11.59zM14.83 14.09c.48 0 .89-.43.89-.91 0-.72.29-1.33.83-1.81.53-.42 1.24-.6 1.89-.48.82.18 1.53.9 1.71 1.75.18.9-.12 1.75-.83 2.29-1.42 1.15-2.24 2.77-2.24 4.52 0 .48.41.91.88.91.48 0 .89-.43.89-.91 0-1.14.59-2.29 1.59-3.13 1.24-.97 1.78-2.54 1.48-4.1-.3-1.57-1.54-2.84-3.07-3.14-1.24-.24-2.42.06-3.37.85-.94.78-1.47 1.92-1.47 3.19-.06.54.29.97.82.97zm2.48 9.34c-.17.18-.23.42-.23.67 0 .24.12.48.23.66.18.18.42.24.65.24.24 0 .48-.12.65-.24.18-.18.24-.42.24-.66 0-.25-.12-.49-.24-.67-.35-.3-.94-.3-1.3 0zM1.55 16.09c0-7.86 5.16-14.19 11.51-14.13h11.21c6.4 0 11.57 6.4 11.57 14.19 0 7.85-5.22 14.19-11.57 14.19H15.9c-.2 0-.41.06-.56.25l-5.37 6.59V30.4c0-.38-.21-.76-.52-.89-4.7-1.9-7.9-7.34-7.9-13.42zm-1.55 0c0 6.71 3.36 12.6 8.42 15.01v6.52c0 .95.62 1.71 1.39 1.71.36 0 .72-.19.98-.5l5.42-6.65h8.11c7.23 0 13.12-7.23 13.12-16.09C37.44 7.22 31.55 0 24.32 0h-11.2C5.89 0 0 7.22 0 16.09z"/>`
  }
})
