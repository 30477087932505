/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'email': {
    width: 26,
    height: 18,
    viewBox: '0 0 26 18',
    data: `<path pid="0" fill-rule="evenodd" _fill="#C4C9CE" d="M13.11 12.04h-.01c-1.75-.01-2.99-.601-3.09-.641l-.06-.029L0 5.24V18h26V4.95c-3.431 2.22-7.86 5.09-9.33 6.06-1.2.78-2.489 1.03-3.56 1.03zM0 0v2.41l11.18 6.9.06.02c.1.04.24.09.42.14.37.11.88.21 1.44.21.69-.011 1.41-.15 2.13-.621 1.7-1.11 7.27-4.72 10.77-6.98V0H0z"/>`
  }
})
