/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'attention': {
    width: 16,
    height: 16,
    viewBox: '0 0 64 64',
    data: `<g id="Icon-Exclamation" transform="translate(228 278)"><path pid="0" class="st0" d="M-196-222.1c-13.2 0-23.9-10.7-23.9-23.9s10.7-23.9 23.9-23.9 23.9 10.7 23.9 23.9-10.7 23.9-23.9 23.9zm0-45.2c-11.7 0-21.3 9.6-21.3 21.3s9.6 21.3 21.3 21.3 21.3-9.6 21.3-21.3-9.6-21.3-21.3-21.3z" id="Fill-49"/><path pid="1" class="st0" id="Fill-50" d="M-197.4-236.1h2.8v2.8h-2.8z"/><path pid="2" class="st0" id="Fill-51" d="M-195.2-238.9h-1.6l-.6-11.3v-8.5h2.8v8.5l-.6 11.3"/></g>`
  }
})
