/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'point-icon1': {
    width: 72,
    height: 72,
    viewBox: '0 0 72 72',
    data: '<path pid="1" d="M25.2 22.344c0-2.952 2.438-5.344 5.445-5.344 1.44 0 2.827.563 3.848 1.565L36 20.046l1.508-1.48A5.496 5.496 0 0141.355 17c3.008 0 5.445 2.392 5.445 5.344v.355a5.67 5.67 0 01-1.837 4.16l-7.733 7.113c-.69.641-1.77.641-2.46 0l-7.733-7.113A5.67 5.67 0 0125.2 22.7v-.355zm-2.4 14.154c.907-.773 2.032-1.237 3.398-1.237 1.372 0 2.692.538 3.667 1.495l4.605 4.52A13.465 13.465 0 0136 43.075c.435-.604.945-1.23 1.53-1.797l4.605-4.521a5.239 5.239 0 013.668-1.495c1.297 0 2.49.464 3.397 1.237v-9.484c0-2.927 2.415-5.302 5.4-5.302 2.985 0 5.4 2.375 5.4 5.302v15.92a8.751 8.751 0 01-2.633 6.251l-5.16 4.934c-.637.75-1.777.75-2.482 0-.697-.626-.697-1.745 0-2.438l5.093-5a5.252 5.252 0 001.582-3.747v-15.92c0-.98-.803-1.767-1.8-1.767-.998 0-1.8.788-1.8 1.767v10.743a7.082 7.082 0 01-2.122 5.037l-4.47 4.256c-.638.75-1.778.75-2.483 0-.697-.626-.697-1.745 0-2.438l1.133-1.17.067-.008 2.01-1.966c.3-.294.465-.692.465-1.104 0-.869-.712-1.569-1.597-1.569-.42 0-.825.163-1.125.457l-4.605 4.521a7.56 7.56 0 00-2.273 5.39v3.77c0 .98-.802 1.767-1.8 1.767s-1.8-.788-1.8-1.767v-3.77a7.56 7.56 0 00-2.273-5.39l-4.604-4.521a1.605 1.605 0 00-1.125-.457c-.886 0-1.598.7-1.598 1.569 0 .412.165.81.465 1.104l3.142 3.144c.765.693.765 1.812 0 2.438-.637.75-1.777.75-2.482 0l-4.402-4.256a7.082 7.082 0 01-2.123-5.037V27.014c0-.98-.806-1.767-1.8-1.767s-1.8.788-1.8 1.767v15.92c0 1.406.57 2.754 1.582 3.748l5.026 5c.765.691.765 1.81 0 2.437-.638.75-1.778.75-2.483 0l-5.089-4.934A8.752 8.752 0 0112 42.934v-15.92c0-2.927 2.418-5.302 5.4-5.302 2.985 0 5.4 2.375 5.4 5.302v9.484z" _fill="#5E2013"/>'
  }
})
