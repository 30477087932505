/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'contact': {
    width: 24,
    height: 22,
    viewBox: '0 0 24 22',
    data: `<path pid="0" _fill="#58B69E" d="M19.19 15.35H11.5c-.53 0-.96.42-.96.95s.43.96.96.96h7.69c.53 0 .96-.43.96-.96s-.43-.95-.96-.95zm0-4.79H11.5a.96.96 0 0 0 0 1.92h7.69a.96.96 0 0 0 0-1.92zm0-4.78H11.5a.96.96 0 0 0 0 1.92h7.69a.96.96 0 0 0 0-1.92zM.96 7.65h1.92c.54 0 .97-.43.97-.95 0-.53-.43-.96-.97-.96H.96A.96.96 0 0 0 0 6.7c0 .52.43.95.96.95zm21.12-3.82c0-1.06-.86-1.92-1.93-1.92H8.62v18.18h11.53c1.07 0 1.93.09 1.93-.96V3.83zm1.92 0v15.3c0 2.11-1.72 2.87-3.85 2.87H5.77c-2.12 0-3.85-1.71-3.85-3.83h.96c1.6 0 2.89-1.28 2.89-2.87 0-1.58-1.29-2.86-2.89-2.86h-.96V9.56h.96c1.6 0 2.89-1.28 2.89-2.86 0-1.59-1.29-2.87-2.89-2.87h-.96C1.92 1.71 3.65 0 5.77 0h14.38C22.28 0 24 1.71 24 3.83zM2.88 16.26H.96A.96.96 0 0 1 0 15.3c0-.52.43-.95.96-.95h1.92c.54 0 .97.43.97.95 0 .53-.43.96-.97.96z"/>`
  }
})
