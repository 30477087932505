/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question-mark': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 1.25c5.93 0 10.75 4.82 10.75 10.75S17.93 22.75 12 22.75 1.25 17.93 1.25 12 6.07 1.25 12 1.25zm0 20c5.1 0 9.25-4.15 9.25-9.25S17.1 2.75 12 2.75 2.75 6.9 2.75 12 6.9 21.25 12 21.25z" _fill="#443C47"/><path pid="1" d="M12 10.25c.41 0 .75.34.75.75v5c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-5c0-.41.34-.75.75-.75zM12 7c.13 0 .26.03.38.08s.23.12.33.21c.09.1.16.2.21.33.05.12.08.25.08.38s-.03.26-.08.38-.12.23-.21.33c-.1.09-.21.16-.33.21a1 1 0 01-.76 0c-.12-.05-.23-.12-.33-.21-.09-.1-.16-.21-.21-.33A.995.995 0 0111 8c0-.13.03-.26.08-.38.05-.13.12-.23.21-.33.1-.09.21-.16.33-.21.12-.05.25-.08.38-.08z" _fill="#443C47"/>'
  }
})
