/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M6.157 20.551l5.862 5.863c2.407 2.407 6.316 2.407 8.736 0l5.681-5.682c2.407-2.407 2.407-6.315 0-8.735l-5.875-5.85a6.148 6.148 0 00-4.66-1.799l-6.47.311a5 5 0 00-4.775 4.75l-.311 6.47a6.217 6.217 0 001.812 4.672z" _stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M13.055 16.28a3.235 3.235 0 100-6.47 3.235 3.235 0 000 6.47z" _stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>'
  }
})
