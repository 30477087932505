/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'sustainable': {
    width: 33.77,
    height: 48.62,
    viewBox: '0 0 33.77 48.62',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M1.67 11.53h1.87c2.96 0 5.84 1.05 8.11 2.95.46.39.9.82 1.35 1.33.95 1.08 1.71 2.29 2.24 3.59.35.88.61 1.83.78 2.92l.34 2.03h-2.05c-6.97 0-12.64-5.68-12.64-12.65v-.17zm15.52 12.82l.32-2.1c.09-.59.21-1.11.35-1.61.66-2.26 1.94-4.3 3.72-5.89.33-.29.68-.58 1.03-.83 2.17-1.57 4.72-2.39 7.38-2.39h1.86v.17c0 6.97-5.67 12.65-12.64 12.65h-2.02zm12.8-14.32c-2.6 0-5.11.7-7.32 2.05.01.45 0 .91-.04 1.36l-1.5-.13.01-.15c-.19.15-.38.31-.56.47a14.195 14.195 0 0 0-3.82 5.56l-.13-.35c-.6-1.46-1.44-2.82-2.51-4.03-.5-.56-.99-1.04-1.5-1.48l-.17-.13c-.53-4.1.93-8.16 3.97-10.96l.13-.12 1.26 1.38c2.35 2.56 3.6 6.16 3.33 9.66l-.01.15 1.5.13c.04-.45.05-.91.04-1.36-.04-3.52-1.39-7.03-3.75-9.6L16.64 0l-1.23 1.14a14.053 14.053 0 0 0-4.55 10.93c-2.2-1.33-4.74-2.04-7.32-2.04H.17v1.67c0 7.8 6.35 14.15 14.14 14.15l1.74-.01v1.55L.83 27.4H.36l-.36.73 9.11 20.49h16.95l7.71-21.22-16.3-.01v-1.55l1.74.01c7.8 0 14.14-6.35 14.14-14.15v-1.67h-3.36zM1.98 28.9h29.65l-6.62 18.21-14.92.01L1.98 28.9z"/>`
  }
})
