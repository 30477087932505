/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star-half': {
    width: 24,
    height: 24,
    viewBox: '2 2 20 20',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" fill="#D3D3D3" d="M12 17.2V2l2.5 7.5H22L16 14l3 8z"/><path pid="1" _fill="#FFC95E" d="M12 17.2L5 22l3-8-6-4.5h7.5L12 2z"/></g>'
  }
})
