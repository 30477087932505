/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'sign_up': {
    width: 34,
    height: 31.87,
    viewBox: '0 0 34 31.87',
    data: `<path pid="0" fill-rule="evenodd" _fill="#02C386" d="M32.64 12.87l-7.4 7.9 7.4 7.74V12.87zm-1.04-1.81l-.66-.62v2.28l1.11-1.18c-.14-.16-.28-.33-.45-.48zM4.76 1.32v12.97l5.41 5.63c.22-.08.48-.06.67.1.15.12.2.28.21.45h12.37c.01-.16.06-.31.19-.43.17-.15.4-.19.61-.15l5.36-5.71V1.32H4.76zm-2.47 9.75c-.11.1-.21.22-.3.33l1.41 1.47v-2.84l-1.11 1.04zm-.93 1.74v15.78c0 .37.12.72.31 1.02.02-.1.06-.2.14-.29l7.41-8.46-7.84-8.16c-.01.04-.02.08-.02.11zM3.4 30.56h27.2c.6 0 1.14-.26 1.51-.67l-7.88-8.23c-.05.01-.1.03-.16.03-.03 0-.06-.01-.09-.02-.11.07-.22.11-.36.11H10.4c-.06 0-.12-.02-.18-.03l-7.38 8.42c-.08.1-.19.15-.31.19.26.12.56.2.87.2zm27.2 1.31H3.4c-1.88 0-3.4-1.47-3.4-3.28V12.81c0-.89.5-1.9 1.35-2.69L3.3 8.3c.03-.03.07-.04.1-.07V.66c0-.36.3-.66.68-.66h26.18c.38 0 .68.3.68.66v7.96l1.62 1.5c.19.18.61.63.96 1.19l.01.02c.26.44.47.95.47 1.48v15.78c0 1.81-1.52 3.28-3.4 3.28z"/>`
  }
})
