/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'logo_small_footer': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M24.45 17.13c-.42.83-1.11 1.32-2.01 1.52-.29.06-.57.08-.86.07h-.07l.07-.41c.04-.35.02-.69-.11-1.02-.19-.44-.54-.67-.99-.77-.48-.1-.95-.03-1.41.13-.67.23-1.24.6-1.66 1.18-.25.35-.39.73-.41 1.16v.1c.1.04.2.07.3.11.36.15.65.38.79.76.18.48.09.92-.21 1.32-.25.33-.59.51-1 .59-.05.01-.07.03-.06.08.04.4.23.71.53.96.17.15.34.28.51.41.25.2.48.41.6.71l.04.11c.05.15.05.16-.09.23-.21.11-.44.12-.67.11-.36-.01-.71-.08-1.05-.18-.05-.02-.09-.01-.12.03l-.83.93c-.22.24-.46.46-.76.6-.14.06-.3.12-.46.13-.12.01-.26-.03-.38-.07-.33-.12-.59-.34-.82-.59-.3-.33-.59-.66-.88-.99-.05-.05-.08-.06-.15-.04-.4.12-.81.2-1.24.18-.19-.01-.37-.04-.53-.15-.05-.03-.07-.07-.05-.13.08-.3.26-.54.49-.74.21-.17.42-.33.63-.5.29-.23.5-.52.57-.9l.02-.17a2.68 2.68 0 0 1-.27-.07c-.46-.14-.8-.42-.99-.87-.26-.61-.05-1.28.53-1.62.17-.1.35-.17.54-.25 0-.31-.06-.61-.2-.9-.3-.6-.77-1.01-1.35-1.31-.42-.22-.87-.36-1.34-.39-.32-.02-.64.02-.93.15-.48.23-.7.62-.76 1.13-.03.29 0 .58.06.86l.01.09-.02.01c-.6.02-1.18-.08-1.71-.36-.69-.37-1.17-.94-1.39-1.68-.42-1.36-.18-2.61.81-3.67.49-.53 1.13-.81 1.85-.9 1.37-.17 2.57.23 3.63 1.08.79.64 1.39 1.43 1.89 2.3.25.45.47.91.66 1.39l.03.07h.02c.01-.2.03-.4.04-.6.02-.87-.18-1.69-.51-2.48-.29-.68-.61-1.35-.92-2.02-.29-.62-.56-1.24-.73-1.9-.16-.6-.24-1.22-.18-1.84.07-.81.35-1.55.79-2.22.35-.53.77-1 1.23-1.44.48-.48.98-.94 1.47-1.42.04-.03.07-.03.1 0 .68.67 1.41 1.29 2.05 2.01.47.53.87 1.1 1.14 1.75.26.65.36 1.33.32 2.02-.04.8-.27 1.55-.58 2.27-.26.59-.54 1.18-.81 1.77-.32.68-.62 1.36-.8 2.08a5.19 5.19 0 0 0-.11 2l.01.03.24-.56c.43-.96.97-1.85 1.7-2.61.68-.73 1.47-1.28 2.44-1.55.77-.21 1.54-.25 2.31-.03.95.27 1.64.87 2.04 1.77.47 1.05.47 2.11-.04 3.15zM14.5 0C6.49 0 0 6.49 0 14.5S6.49 29 14.5 29 29 22.51 29 14.5 22.51 0 14.5 0z"/>`
  }
})
