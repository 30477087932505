/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'most_popular': {
    width: 40.004,
    height: 40,
    viewBox: '0 0 40.004 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M21.962 12.44a.811.811 0 0 1-.72-.53l-1.24-3.36-1.24 3.36c-.11.31-.4.51-.72.53l-3.58.14 2.81 2.22c.26.2.37.53.28.84l-.97 3.45 2.98-1.99c.13-.09.29-.14.44-.14s.31.05.44.14l2.98 1.99-.97-3.45c-.09-.31.02-.64.28-.84l2.81-2.22-3.58-.14zm2.18 3.28l1.41 5.02c.09.32-.03.67-.3.86-.14.1-.31.16-.47.16-.15 0-.31-.05-.44-.14l-4.34-2.89-4.34 2.89c-.27.19-.64.18-.91-.02a.794.794 0 0 1-.3-.86l1.41-5.02-4.09-3.23a.784.784 0 0 1-.26-.87c.1-.32.39-.54.72-.56l5.21-.2 1.81-4.89c.23-.63 1.27-.63 1.5 0l1.81 4.89 5.21.2c.33.02.62.24.72.56.11.32 0 .66-.26.87l-4.09 3.23zm7.56 5.62a13.872 13.872 0 0 1-9.5 6.25l4.71 9.64 2.82-7.05c.14-.36.52-.57.9-.49l6.95 1.39-5.88-9.74zM20.002 1.6c-6.78 0-12.29 5.51-12.29 12.29 0 6.78 5.51 12.29 12.29 12.29s12.29-5.51 12.29-12.29c0-6.78-5.51-12.29-12.29-12.29zM2.422 31.08l6.95-1.39c.38-.08.76.13.9.49l2.82 7.05 4.71-9.64c-3.99-.64-7.41-2.97-9.5-6.25l-5.88 9.74zm37.43 1.6a.78.78 0 0 1-.81.32l-8.07-1.61-3.24 8.11c-.12.29-.4.49-.72.5h-.03c-.3 0-.58-.17-.72-.45l-5.77-11.79c-.16 0-.32.02-.49.02s-.33-.02-.49-.02l-5.77 11.79c-.14.28-.42.45-.72.45h-.03a.809.809 0 0 1-.72-.5l-3.24-8.11L.962 33a.78.78 0 0 1-.81-.32c-.19-.26-.2-.6-.04-.87l7.3-12.09c-.83-1.78-1.3-3.75-1.3-5.83C6.112 6.23 12.342 0 20.002 0s13.89 6.23 13.89 13.89c0 2.08-.47 4.05-1.3 5.83l7.3 12.09c.16.27.15.61-.04.87z"/>`
  }
})
