/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'best_local_guides-active': {
    width: 64,
    height: 58,
    viewBox: '0 0 64 58',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M15.03 2.59h33.94v17.25c0 9.27-7.61 16.82-16.97 16.82s-16.97-7.55-16.97-16.82V2.59zm17.94 36.63c10.26-.5 18.47-8.86 18.61-19.12h-.2a1.289 1.289 0 1 1 0-2.58l.2-.01V2.59h9.81V7.6c0 5.4-4.38 9.81-9.81 9.91v2.59C58.45 20 64 14.43 64 7.6V1.29C64 .58 63.42 0 62.69 0H1.31C.58 0 0 .58 0 1.29V7.6C0 14.43 5.55 20 12.42 20.1v-2.59C6.99 17.41 2.61 13 2.61 7.6V2.59h9.81v14.92l.2.01a1.289 1.289 0 1 1 0 2.58h-.2c.14 10.04 8 18.26 17.94 19.07v9.55h-6.74a1.3 1.3 0 0 0-1.31 1.3c0 .71.58 1.29 1.31 1.29h16.1a1.295 1.295 0 1 0 0-2.59h-6.75v-9.5zM51.58 20.1v-2.59l-.2.01a1.289 1.289 0 1 0 0 2.58h.2zm-39.16-2.59v2.59h.2a1.289 1.289 0 1 0 0-2.58l-.2-.01zM52.12 58a1.295 1.295 0 1 0 0-2.59H11.21a1.295 1.295 0 0 0 0 2.59h40.91z"/>`
  }
})
