/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'promotions': {
    width: 40,
    height: 39,
    viewBox: '0 0 40 39',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M34.47 13.11v9.67c2.27-.38 4.01-2.39 4.01-4.84 0-2.44-1.74-4.45-4.01-4.83zm-1.52-10.8a.74.74 0 0 0-.4-.67.695.695 0 0 0-.76.07c-4.91 3.85-13.47 10.15-17.74 10.88v10.65c4.82.64 13.09 6.94 17.73 10.77.32.26.64.15.76.09.1-.05.41-.23.41-.67V2.31zM6.63 12.66c-2.82 0-5.11 2.36-5.11 5.27 0 2.9 2.29 5.26 5.11 5.26h.59c.01 0 .03-.02.04-.02l5.27.02V12.66h-5.9zm3.79 24.09c1.04.54 4.97 1.05 6.74.33.34-.14.56-.31.62-.48.42-1.18-.4-2.05-2.01-3.59-.37-.35-.75-.71-1.13-1.1-1.74-1.8-2.09-5.53-2.15-7.16H8.02v4.15c0 4.26 1.42 7.35 2.4 7.85zm24.05-12.41v9.09c0 .89-.49 1.69-1.27 2.08-.32.15-.65.23-.97.23-.5 0-.99-.17-1.39-.51-7.53-6.2-13.55-9.9-16.83-10.39.08 1.75.47 4.7 1.7 5.96.37.38.74.73 1.09 1.07 1.64 1.57 3.2 3.05 2.4 5.27-.22.61-.72 1.08-1.49 1.39-.82.34-1.91.47-3.04.47-2 0-4.09-.42-4.93-.85C7.9 37.2 6.5 33.22 6.5 28.9v-4.17c-3.59-.07-6.5-3.09-6.5-6.8 0-3.77 2.98-6.83 6.63-6.83h6.66c2.51 0 9.09-3.97 17.59-10.63.68-.53 1.58-.62 2.34-.23.77.39 1.25 1.18 1.25 2.07v9.24c3.1.39 5.53 3.09 5.53 6.39 0 3.31-2.43 6.01-5.53 6.4z"/>`
  }
})
