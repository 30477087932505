/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 10.44,
    height: 23,
    viewBox: '0 0 10.44 23',
    data: `<path pid="0" _fill="#FFF" d="M6.94 23H2.31V11.5H0V7.54h2.31V5.16C2.31 1.92 3.62 0 7.33 0h3.09v3.96H8.49c-1.45 0-1.54.56-1.54 1.59l-.01 1.99h3.5l-.41 3.96H6.94V23z"/><path pid="1" _fill="#FFF" d="M6.94 23H2.31V11.5H0V7.54h2.31V5.16C2.31 1.92 3.62 0 7.33 0h3.09v3.96H8.49c-1.45 0-1.54.56-1.54 1.59l-.01 1.99h3.5l-.41 3.96H6.94V23z"/>`
  }
})
