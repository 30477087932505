/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'small_groups-active': {
    width: 62,
    height: 53,
    viewBox: '0 0 62 53',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M44.69 36.73c-2.31-2.46-5.23-4.2-8.48-5.09a13.06 13.06 0 0 0 5.75-4.59c.36-.02.71-.05 1.07-.05 4.5 0 8.52 1.79 11.47 4.92 2.69 2.87 4.45 6.89 4.73 11.74H48.86c-.95-2.66-2.37-5.01-4.17-6.93zm-24.91 1.84c2.94-3.13 6.97-4.92 11.46-4.93 4.5.01 8.52 1.8 11.46 4.93 2.69 2.86 4.46 6.88 4.74 11.74h-32.4c.29-4.86 2.05-8.88 4.74-11.74zm.76-11.49c1.42 2.01 3.41 3.61 5.73 4.56-3.25.89-6.17 2.63-8.47 5.09-1.8 1.92-3.22 4.27-4.18 6.93H2.77c.28-4.85 2.04-8.87 4.74-11.74C10.45 28.79 14.47 27 18.97 27c.53 0 1.05.03 1.57.08zm18.03-14.73c1.88 1.87 3.03 4.43 3.04 7.28-.01 2.85-1.16 5.41-3.04 7.28a10.372 10.372 0 0 1-7.33 3.01c-2.86 0-5.45-1.15-7.33-3.01-1.87-1.87-3.03-4.43-3.03-7.28s1.16-5.41 3.03-7.28a10.39 10.39 0 0 1 7.33-3.01c2.87 0 5.45 1.15 7.33 3.01zm4.46-9.66c2.87 0 5.45 1.15 7.33 3.02 1.88 1.86 3.04 4.43 3.04 7.27 0 2.85-1.16 5.42-3.04 7.28-1.71 1.7-4.01 2.79-6.57 2.98.34-1.15.53-2.36.53-3.61 0-6.03-4.15-11.09-9.77-12.55.35-.49.73-.95 1.16-1.37 1.88-1.87 4.46-3.02 7.32-3.02zm-24.06 0c2.86 0 5.45 1.15 7.32 3.02.46.45.87.95 1.24 1.48-5.41 1.59-9.36 6.55-9.36 12.44 0 1.26.19 2.48.53 3.63-2.76-.07-5.24-1.19-7.06-3a10.197 10.197 0 0 1-3.04-7.28c0-2.84 1.16-5.41 3.04-7.27 1.88-1.87 4.46-3.02 7.33-3.02zm37.51 27.39a18.18 18.18 0 0 0-8.47-5.09c4.75-1.94 8.1-6.58 8.1-12.01C56.11 5.81 50.25 0 43.03 0c-4.91 0-9.18 2.69-11.41 6.67-.13-.01-.25-.02-.38-.02-.28 0-.56.02-.84.04A13.072 13.072 0 0 0 18.97 0C11.75 0 5.89 5.81 5.89 12.98c0 5.43 3.35 10.07 8.1 12.01a18.18 18.18 0 0 0-8.47 5.09C2.08 33.74 0 38.93 0 45.01c0 .35.14.7.4.95.25.25.6.39.96.39h11.5c-.37 1.68-.59 3.44-.58 5.3 0 .36.14.71.39.96s.6.39.96.39h35.22c.36 0 .71-.14.96-.39s.4-.6.4-.96c0-1.86-.22-3.62-.59-5.3h11.03c.35 0 .7-.14.95-.39.26-.25.4-.6.4-.95 0-6.08-2.08-11.27-5.52-14.93z"/>`
  }
})
