/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'hamburger': {
    width: 36,
    height: 26,
    viewBox: '0 0 36 26',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFFFFF" d="M0 0v4h36V0H0zm0 11v4h36v-4H0zm0 11v4h36v-4H0z"/>`
  }
})
