/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star_top_inactive': {
    width: 25.23,
    height: 23,
    viewBox: '0 0 25.23 23',
    data: '<path pid="0" _fill="#D3D3D3" d="M12.62 0l3.4 8.22 9.21.56-7.09 5.65L20.41 23l-7.79-4.72L4.83 23l2.27-8.57L0 8.78l9.21-.56z"/>'
  }
})
