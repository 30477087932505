/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'instagram': {
    width: 23.2,
    height: 21.85,
    viewBox: '0 0 23.2 21.85',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M11.6 14.55c-2.08 0-3.77-1.62-3.77-3.62s1.69-3.62 3.77-3.62 3.76 1.62 3.76 3.62-1.68 3.62-3.76 3.62zm0-9.23c-3.23 0-5.84 2.51-5.84 5.61s2.61 5.61 5.84 5.61c3.22 0 5.83-2.51 5.83-5.61s-2.61-5.61-5.83-5.61zm7.46-.31c0-.7-.59-1.26-1.32-1.26-.72 0-1.31.56-1.31 1.26s.59 1.27 1.31 1.27c.73 0 1.32-.57 1.32-1.27zm2.06 10.81c0 2.24-1.9 4.07-4.23 4.07H6.31c-2.33 0-4.24-1.83-4.24-4.07V6.03c0-2.24 1.91-4.07 4.24-4.07h10.58c2.33 0 4.23 1.83 4.23 4.07v9.79zM17.08 0H6.11C2.75 0 0 2.65 0 5.88v10.09c0 3.23 2.75 5.88 6.11 5.88h10.97c3.37 0 6.12-2.65 6.12-5.88V5.88C23.2 2.65 20.45 0 17.08 0z"/>`
  }
})
