/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'most-popular-destinations': {
    width: 32,
    height: 40,
    viewBox: '0 0 32 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M14 13.24c-.7-.21-1.55-.32-2.2 0-.91.46-1.8 1.29-1.8 2.44 0 3.45 6 7.32 6 7.32s6-3.87 6-7.32c0-1.15-.86-1.98-1.77-2.44-.66-.33-1.61-.31-2.23 0-.87.44-1.94 1.36-1.94 1.36s-1.27-1.12-2.06-1.36zm2-11.46c7.84 0 14.22 6.38 14.22 14.22 0 9.77-11.56 19.8-14.22 21.97C13.34 35.81 1.78 25.81 1.78 16 1.78 8.16 8.16 1.78 16 1.78zM16 40c.19 0 .38-.06.54-.18C17.17 39.33 32 27.86 32 16c0-8.82-7.18-16-16-16S0 7.18 0 16c0 11.91 14.83 23.34 15.46 23.82.16.12.35.18.54.18z"/>`
  }
})
