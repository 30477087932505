/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'quote_big': {
    width: 161,
    height: 154,
    viewBox: '0 0 161 154',
    data: `<path pid="0" fill-rule="evenodd" _fill="#F3F4F6" d="M71.78 81.67H36.16c-.18-1.69-.27-3.42-.27-5.16 0-23.81 17.13-43.59 39.66-47.51L61.16 0C25.96 9.3 0 41.57 0 79.95V154h71.78V81.67zm85.45 0h-35.61c-.18-1.69-.28-3.42-.28-5.16 0-23.81 17.14-43.59 39.66-47.51L146.61 0c-35.19 9.3-61.16 41.57-61.16 79.95V154h71.78V81.67z"/>`
  }
})
