/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'colosseum': {
    width: 39,
    height: 39,
    viewBox: '0 0 39 39',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M37.56 36.83c0 .4-.33.73-.73.73h-1.44v-6.5c0-2.79-1.94-5.06-4.33-5.06s-4.34 2.27-4.34 5.06v6.5h-2.89v-6.5c0-2.79-1.94-5.06-4.33-5.06s-4.33 2.27-4.33 5.06v6.5h-2.89v-6.5c0-2.79-1.95-5.06-4.34-5.06s-4.33 2.27-4.33 5.06v6.5H2.17c-.4 0-.73-.33-.73-.73v-13c0-.39.33-.72.73-.72h34.66c.4 0 .73.33.73.72v13zm-3.62-18.77h-5.77V13c0-1.99 1.29-3.61 2.89-3.61 1.59 0 2.88 1.62 2.88 3.61v5.06zm-11.55 0h-5.78V13c0-1.99 1.3-3.61 2.89-3.61s2.89 1.62 2.89 3.61v5.06zm-11.56 0H5.06V13c0-1.99 1.29-3.61 2.88-3.61 1.6 0 2.89 1.62 2.89 3.61v5.06zm26.73 2.16v.72c0 .4-.33.73-.73.73H2.17c-.4 0-.73-.33-.73-.73v-.72c0-.4.33-.72.73-.72h34.66c.4 0 .73.32.73.72zm0-14.44v11.55c0 .4-.33.73-.73.73h-1.44V13c0-2.79-1.94-5.06-4.33-5.06s-4.34 2.27-4.34 5.06v5.06h-2.89V13c0-2.79-1.94-5.06-4.33-5.06s-4.33 2.27-4.33 5.06v5.06h-2.89V13c0-2.79-1.95-5.06-4.34-5.06S3.61 10.21 3.61 13v5.06H2.17c-.4 0-.73-.33-.73-.73V5.78c0-.4.33-.72.73-.72h34.66c.4 0 .73.32.73.72zM1.44 2.17c0-.4.33-.73.73-.73h34.66c.4 0 .73.33.73.73v.72c0 .4-.33.72-.73.72H2.17c-.4 0-.73-.32-.73-.72v-.72zm37.56 0C39 .97 38.03 0 36.83 0H2.17C.97 0 0 .97 0 2.17v.72c0 .56.22 1.06.56 1.44-.34.39-.56.89-.56 1.45v11.55c0 .56.22 1.06.56 1.45-.34.38-.56.89-.56 1.44v.72c0 .56.22 1.06.56 1.45-.34.38-.56.89-.56 1.44v13C0 38.03.97 39 2.17 39h2.16c.4 0 .73-.32.73-.72v-7.22c0-2 1.29-3.62 2.88-3.62 1.6 0 2.89 1.62 2.89 3.62v7.22c0 .4.33.72.73.72h4.33c.4 0 .72-.32.72-.72v-7.22c0-2 1.3-3.62 2.89-3.62s2.89 1.62 2.89 3.62v7.22c0 .4.32.72.72.72h4.33c.4 0 .73-.32.73-.72v-7.22c0-2 1.29-3.62 2.89-3.62 1.59 0 2.88 1.62 2.88 3.62v7.22c0 .4.33.72.73.72h2.16c1.2 0 2.17-.97 2.17-2.17v-13c0-.55-.22-1.06-.56-1.44.34-.39.56-.89.56-1.45v-.72c0-.55-.22-1.06-.56-1.44.34-.39.56-.89.56-1.45V5.78c0-.56-.22-1.06-.56-1.45.34-.38.56-.88.56-1.44v-.72z"/>`
  }
})
