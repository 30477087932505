/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'max': {
    width: 39,
    height: 32,
    viewBox: '0 0 39 32',
    data: `<path pid="0" fill-rule="evenodd" _fill="#D3D3D3" d="M14.62 3.2c-3.59 0-6.5 2.86-6.5 6.39s2.91 6.4 6.5 6.4c3.6 0 6.51-2.87 6.51-6.4 0-3.53-2.91-6.39-6.51-6.39zM3.37 28.81h22.56c-.71-5.16-5-9.2-10.34-9.63-.31.03-.62.05-.94.05-.31 0-.62-.02-.93-.05-5.34.43-9.63 4.47-10.35 9.63zm21.07-19.2c0 3.1-1.5 5.84-3.8 7.6 5.08 2.25 8.62 7.26 8.62 13.1 0 .55-.03 1.09-.1 1.63h-1.53V32H1.66v-.06H.1c-.06-.54-.1-1.08-.1-1.63 0-5.84 3.56-10.87 8.65-13.11-2.3-1.76-3.78-4.5-3.78-7.59C4.87 4.3 9.25 0 14.65 0c5.41 0 9.79 4.3 9.79 9.61zM39 30.31c0 .55-.04 1.09-.1 1.63h-1.54V32h-6.49v-3.19h4.8c-.67-4.8-4.42-8.62-9.23-9.48-.63-.65-1.33-1.22-2.06-1.75v-1.59c3.59-.01 6.49-2.87 6.49-6.4 0-3.53-2.91-6.39-6.51-6.39-.15 0-.29.01-.44.02A11.06 11.06 0 0 0 21.24.57c.99-.33 2.05-.52 3.15-.52 5.4 0 9.78 4.3 9.78 9.61 0 3.08-1.47 5.81-3.76 7.57C35.47 19.49 39 24.49 39 30.31z"/>`
  }
})
