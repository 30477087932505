/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'vatican-tours': {
    width: 19,
    height: 40,
    viewBox: '0 0 19 40',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M8.73 4.36v2.87h-3v1.66h3v4.79h1.63V8.89h3.03V7.23h-3.03V4.36H8.73zm.81 23.53c.73 0 1.43-.18 2.07-.49.09.23.2.45.33.66.31.51.7.92 1.12 1.25-1.21.53-2.51.78-3.82.71-1.11-.03-2.18-.31-3.19-.79.38-.32.73-.71 1.01-1.17.14-.22.25-.46.34-.7.67.34 1.39.53 2.14.53zm2.92-1.01c1.78-1.33 2.99-3.83 2.99-6.71 0-.96-.11-1.84-.36-2.72l2.5-8.13-.05-.17c-.59-1.79-1.54-3.49-2.82-4.97-1.37-1.65-3.04-3-5.01-4.05L9.5 0l-.21.13C7.32 1.22 5.65 2.57 4.28 4.18 3.04 5.66 2.05 7.36 1.46 9.15l-.05.17L4 17.47a9.87 9.87 0 0 0-.36 2.7c0 2.84 1.17 5.32 2.91 6.66-.06.24-.17.48-.3.71-.64 1.04-1.8 1.61-2.48 1.87-2.1.05-3.77 1.44-3.77 3.18V40h19v-7.41c0-1.74-1.67-3.13-3.77-3.18-.68-.26-1.84-.83-2.48-1.87-.12-.21-.22-.43-.29-.66zm1.74-8.93c.17.73.26 1.46.26 2.22 0 3.71-2.18 6.71-4.92 6.71-2.74 0-4.92-3-4.92-6.71 0-.77.09-1.51.28-2.22h9.3zm-.16 11.99c.37.19.71.33.98.43l.09.04h.08c.58 0 1.13.14 1.58.37-1.71 2.49-4.56 3.94-7.53 3.82-3.78-.14-6.07-2.81-6.85-3.9.42-.18.9-.29 1.42-.29h.08l.09-.04c.3-.1.7-.26 1.12-.49 1.24.69 2.61 1.06 4.06 1.14h.43c1.53 0 3.06-.38 4.45-1.08zm3.52 1.44c.32.35.5.77.5 1.21V39h-5.69v-3.78c2.06-.64 3.89-1.98 5.19-3.84zM11.34 39H7.66v-3.55c.47.1.97.17 1.5.19h.43c.59 0 1.18-.05 1.75-.16V39zm-4.66 0H.94v-6.41c0-.51.23-.98.61-1.35.68.97 2.37 3.02 5.13 3.95V39zm-1.8-22.05L2.44 9.32c.6-1.61 1.45-3.13 2.61-4.48 1.24-1.44 2.74-2.7 4.49-3.66a15.6 15.6 0 0 1 4.5 3.66c1.15 1.35 2.01 2.87 2.61 4.48l-2.44 7.63H4.88z"/>`
  }
})
