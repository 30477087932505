/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'logo_badges': {
    width: 36.805,
    height: 41.229,
    viewBox: '0 0 36.805 41.229',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M36.108 25.335c-.74 1.49-1.97 2.37-3.58 2.72-.5.11-1 .15-1.52.14l-.12-.01c.04-.25.08-.5.11-.74.08-.62.05-1.24-.19-1.82-.33-.78-.95-1.21-1.75-1.38-.87-.19-1.7-.06-2.52.23-1.19.41-2.21 1.08-2.95 2.12-.44.62-.69 1.31-.73 2.07v.19c.18.06.35.12.52.19.65.27 1.17.69 1.42 1.37.32.85.16 1.64-.38 2.36-.44.59-1.05.93-1.77 1.06-.1.02-.13.05-.12.15.08.71.42 1.27.95 1.72.3.26.61.49.91.74.45.35.85.73 1.07 1.27l.07.2c.08.26.08.27-.17.41-.37.2-.78.21-1.19.2-.64-.02-1.26-.15-1.87-.33-.09-.03-.15-.02-.21.05l-1.48 1.67c-.39.43-.81.84-1.35 1.08-.25.12-.53.21-.81.23-.22.02-.47-.05-.69-.13-.58-.21-1.04-.61-1.45-1.06a132.9 132.9 0 0 1-1.58-1.77c-.07-.08-.14-.1-.25-.07-.72.21-1.46.37-2.22.33-.34-.02-.66-.08-.95-.28-.08-.06-.1-.11-.08-.22.15-.54.46-.98.88-1.34.36-.3.74-.58 1.11-.89.52-.42.9-.94 1.02-1.62l.04-.31c-.17-.04-.33-.07-.48-.11-.82-.27-1.42-.77-1.76-1.56-.47-1.11-.09-2.31.94-2.92.29-.18.62-.3.96-.45 0-.54-.11-1.09-.36-1.61-.53-1.07-1.37-1.8-2.4-2.35a5.9 5.9 0 0 0-2.39-.7c-.57-.03-1.13.03-1.66.28-.84.4-1.24 1.1-1.34 2.01-.06.53 0 1.05.1 1.56l.02.16c-.01 0-.03.02-.05.02-1.06.02-2.09-.15-3.04-.65-1.23-.67-2.07-1.68-2.47-3.01-.74-2.46-.32-4.69 1.44-6.58.88-.95 2.03-1.46 3.3-1.62 2.43-.31 4.57.4 6.47 1.93 1.4 1.14 2.47 2.57 3.35 4.13.45.81.84 1.64 1.18 2.49l.05.13.04-.01c.03-.36.06-.71.07-1.07.04-1.56-.32-3.03-.91-4.44-.51-1.23-1.08-2.43-1.64-3.64-.5-1.1-.99-2.22-1.3-3.4a9.449 9.449 0 0 1-.31-3.31c.12-1.45.62-2.76 1.41-3.98.62-.94 1.37-1.79 2.17-2.58.88-.85 1.76-1.69 2.64-2.54.06-.06.12-.06.17 0 1.21 1.21 2.52 2.31 3.65 3.6.83.95 1.55 1.97 2.02 3.15.47 1.16.65 2.37.58 3.62-.08 1.42-.48 2.77-1.04 4.06-.45 1.07-.96 2.12-1.45 3.18-.56 1.21-1.08 2.44-1.4 3.74-.29 1.18-.38 2.38-.21 3.59l.02.04c.15-.35.28-.68.43-1 .77-1.72 1.72-3.31 3.02-4.69 1.21-1.29 2.62-2.29 4.35-2.77 1.36-.38 2.74-.45 4.11-.06 1.69.49 2.92 1.56 3.63 3.18.83 1.89.84 3.79-.08 5.65z"/>`
  }
})
