/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  '911-memorial-tours': {
    width: 22,
    height: 42,
    viewBox: '0 0 22 42',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M21.29 40.93H.71c-.39 0-.71.24-.71.54 0 .29.32.53.71.53h20.58c.39 0 .71-.24.71-.53 0-.3-.32-.54-.71-.54zM6.78 10.44c.39 0 .71-.24.71-.54v-.83h7.02v.83c0 .3.32.54.71.54.4 0 .71-.24.71-.54V8.54c0-.29-.31-.53-.71-.53h-3.51V.53c0-.29-.32-.53-.71-.53s-.71.24-.71.53v7.48H6.78c-.4 0-.71.24-.71.53V9.9c0 .3.31.54.71.54zm8.96 1.44l.7 19.41-4.63-18.63c-.05-.26-.35-.45-.7-.45s-.64.19-.7.45L5.55 31.55l.7-19.67h9.49zM4.65 40.18c.39 0 .71-.24.71-.53v-3.34l5.74-20.26 5.54 20.26v3.34c0 .29.32.53.71.53s.71-.24.71-.53v-3.39l-.91-24.92c-.01-.29-.32-.52-.71-.52H5.55c-.39 0-.7.23-.71.52l-.9 24.91v3.4c0 .29.32.53.71.53zm2.25-4.45c-.4 0-.72.24-.72.53 0 .3.32.53.72.53h8.2c.4 0 .72-.23.72-.53 0-.29-.32-.53-.72-.53H6.9z"/>`
  }
})
