/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cart': {
    width: 256,
    height: 256,
    viewBox: '0 0 256 256',
    data: '<g transform="matrix(2.81 0 0 2.81 1.407 1.407)" stroke-width="0" stroke-miterlimit="10" _fill="#000"><path pid="0" d="M72.975 58.994h-41.12a3.5 3.5 0 01-3.347-2.477L15.199 13.006H3.5a3.5 3.5 0 110-7h14.289a3.5 3.5 0 013.347 2.476l13.309 43.512h36.204l10.585-25.191h-6.021a3.5 3.5 0 110-7H86.5a3.5 3.5 0 013.227 4.856L76.201 56.85a3.5 3.5 0 01-3.226 2.144z"/><circle pid="1" cx="28.88" cy="74.33" r="6.16"/><circle pid="2" cx="74.59" cy="74.33" r="6.16"/><path pid="3" d="M48.188 40.595l-1.705-.951c-3.698-2.063-9.26-6.75-11.669-9.834-5.495-7.033-6.395-15.264-2.188-20.017 2.061-2.328 4.943-3.67 8.117-3.78 2.582-.084 5.153.659 7.445 2.142 2.293-1.482 4.864-2.235 7.446-2.141 3.174.11 6.056 1.452 8.116 3.779 4.207 4.752 3.307 12.983-2.188 20.018-2.409 3.083-7.971 7.771-11.669 9.833l-1.705.951zm-7.033-27.589a4.72 4.72 0 00-.169.003c-1.235.042-2.342.548-3.117 1.423-1.811 2.046-.707 7.011 2.462 11.068 1.519 1.944 5.046 5.025 7.858 6.957 2.813-1.932 6.34-5.013 7.858-6.957 3.169-4.057 4.273-9.021 2.462-11.068-.774-.875-1.881-1.381-3.116-1.423-1.621-.053-3.308.694-4.75 2.111l-2.454 2.414-2.455-2.414c-1.39-1.368-3.01-2.114-4.579-2.114z"/></g>'
  }
})
