/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'guaranteed_departures': {
    width: 59.001,
    height: 58.998,
    viewBox: '0 0 59.001 58.998',
    data: `<path pid="0" fill-rule="evenodd" _fill="#F4F5F6" d="M55.661 25.208l-6.98-20.08c-.35-1-1.07-1.81-2.04-2.28-.95-.45-2.02-.52-3.01-.17l-38.52 13.58c-2.06.72-3.15 3.01-2.43 5.09l2.89 8.32c.72-2.78 3.21-4.85 6.2-4.85h40.81c1.14 0 2.22.34 3.18.9-.04-.17-.05-.35-.1-.51z"/><path pid="1" fill-rule="evenodd" _fill="#E4E6E8" d="M32.181 32.948h-18.66a1.23 1.23 0 1 1 0-2.46h18.66c.67 0 1.23.55 1.23 1.23 0 .68-.56 1.23-1.23 1.23zm0 7.15h-18.66a1.23 1.23 0 1 1 0-2.46h18.66c.67 0 1.23.55 1.23 1.23 0 .68-.56 1.23-1.23 1.23zm0 7.16h-18.66a1.23 1.23 0 1 1 0-2.46h18.66c.67 0 1.23.55 1.23 1.23 0 .68-.56 1.23-1.23 1.23zm18.22 2.83c-.12.12-.43.03-.89-.21l-1.93 3.05-1-1.01.94-3.4c-1.05-.81-2.29-1.85-3.55-2.98l-4.56 6.4-1.48-1.49 3.15-7.62-.48-.49c-3.84-3.86-4.67-5.65-4.09-6.23.58-.59 2.35.26 6.19 4.12l.48.49 7.57-3.18 1.48 1.48-6.35 4.61c1.12 1.26 2.15 2.51 2.95 3.57l3.38-.95 1 1.01-3.03 1.94c.24.46.34.78.22.89zm6.14-18.81c0-.92-.32-1.83-.91-2.54a3.927 3.927 0 0 0-3.05-1.46h-40.81c-2.18 0-3.96 1.8-3.96 4v21.26c0 2.21 1.78 4 3.96 4h40.81c2.19 0 3.96-1.79 3.96-4v-21.26zm-.88-6.07l-6.98-20.08c-.35-1-1.07-1.81-2.04-2.28-.95-.45-2.02-.52-3.01-.17l-38.52 13.58c-2.06.72-3.15 3.01-2.43 5.09l2.89 8.32c.72-2.78 3.21-4.85 6.2-4.85h40.81c1.14 0 2.22.34 3.18.9-.04-.17-.05-.35-.1-.51zm3.34 27.33c0 3.56-2.88 6.46-6.42 6.46h-40.81c-3.54 0-6.42-2.9-6.42-6.46v-16.26c-.09-.1-.16-.22-.21-.36l-4.78-13.76c-1.17-3.36.6-7.05 3.94-8.22L42.821.358a6.39 6.39 0 0 1 4.89.28c1.56.75 2.72 2.06 3.29 3.69l6.98 20.07c.38 1.09.46 2.26.23 3.39-.02.09-.07.16-.1.23.57.99.89 2.11.89 3.26v21.26z"/>`
  }
})
