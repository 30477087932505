/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'new': {
    width: 21.997,
    height: 30.001,
    viewBox: '0 0 21.997 30.001',
    data: `<path pid="0" fill-rule="evenodd" _fill="#FFF" d="M10.999 7.19c2.26 0 4.1 1.85 4.1 4.12s-1.84 4.12-4.1 4.12-4.1-1.85-4.1-4.12 1.84-4.12 4.1-4.12zm0 9.94c3.19 0 5.79-2.61 5.79-5.82s-2.6-5.82-5.79-5.82-5.79 2.61-5.79 5.82 2.6 5.82 5.79 5.82zm-1.92 1.52c-.12 0-.23.02-.35.07l-1.73.78-.44-1.85a.853.853 0 0 0-.8-.65l-1.9-.05.4-1.87c.08-.37-.1-.75-.44-.93l-1.68-.87 1.16-1.51c.23-.3.24-.73.01-1.03l-1.15-1.53 1.7-.85c.34-.17.53-.55.46-.92l-.38-1.87 1.9-.03c.38 0 .71-.27.81-.64l.47-1.84 1.72.8c.35.16.76.07 1-.22l1.22-1.46 1.21 1.48c.24.29.65.39 1 .23l1.73-.78.44 1.86c.09.37.42.63.8.65l1.9.05-.4 1.86c-.08.38.1.76.44.94l1.68.87-1.16 1.51c-.23.3-.24.72-.01 1.03l1.15 1.52-1.7.85c-.34.17-.53.55-.46.93l.38 1.87-1.9.03c-.38 0-.71.26-.81.63l-.47 1.85-1.72-.8a.815.815 0 0 0-1 .22l-1.22 1.46-1.21-1.48a.831.831 0 0 0-.65-.31zm1.839 3.87c.25 0 .49-.06.65-.25l1.46-1.7 2.06.96c.23.11.49.11.72 0a.85.85 0 0 0 .45-.56l.57-2.2 2.27-.03a.851.851 0 0 0 .81-1.02l-.44-2.24 2.03-1.01c.22-.11.39-.32.44-.57.06-.24.01-.5-.14-.7l-1.37-1.82 1.39-1.81c.16-.2.21-.45.16-.7s-.21-.46-.44-.57l-2.01-1.05.47-2.23c.06-.24 0-.5-.16-.7a.805.805 0 0 0-.64-.32l-2.27-.07-.53-2.21a.862.862 0 0 0-.45-.57.862.862 0 0 0-.72-.01l-2.07.94-1.43-1.77c-.16-.19-.4-.31-.65-.31s-.49.11-.65.3l-1.46 1.75-2.06-.97c-.23-.1-.49-.1-.72.01-.22.1-.39.31-.45.55l-.57 2.21-2.27.03a.841.841 0 0 0-.81 1.02l.44 2.23-2.03 1.02a.83.83 0 0 0-.44.56.85.85 0 0 0 .14.71l1.37 1.82-1.39 1.8c-.16.2-.21.46-.16.71.05.24.21.45.44.57l2.01 1.04-.47 2.23c-.06.25 0 .51.16.71.15.19.39.31.64.32l2.27.06.53 2.22c.06.24.22.44.45.56.23.11.49.11.72 0l2.07-.94 1.43 1.74c.161.19.4.26.65.26zM16.139 30c.15 0 .29-.04.42-.11.27-.15.43-.43.43-.74v-5.61a.85.85 0 0 0-.85-.85c-.46 0-.84.38-.84.85v4.11l-3.96-2.37a.848.848 0 0 0-.87 0l-3.96 2.37v-4.11c0-.47-.38-.85-.84-.85a.85.85 0 0 0-.85.85v5.61c0 .31.17.59.43.74.27.15.59.15.85-.01l4.81-2.88 4.8 2.88c.129.08.28.12.43.12z"/>`
  }
})
