/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'reviews': {
    width: 50,
    height: 52,
    viewBox: '0 0 50 52',
    data: `<path pid="0" fill-rule="evenodd" _fill="#3AB977" d="M40 27.8c0 10.94-8.95 19.62-20 19.62-1.85 0-3.75-.44-5.45-.92L5.45 52V41.92C1.92 38.35 0 33.19 0 27.8 0 16.86 8.95 8 20 8s20 8.86 20 19.8z"/><path pid="1" fill-rule="evenodd" _fill="#3AB977" d="M37.2 27.54c0-9.78-7.88-17.7-17.6-17.7C9.88 9.84 2 17.76 2 27.54c0 4.82 1.69 9.43 4.8 12.62v9.02l8-4.92c1.5.43 3.17.82 4.8.82 9.72 0 17.6-7.76 17.6-17.54zm-2.2.21c0 8.7-6.94 15.6-15.5 15.6-1.44 0-2.91-.35-4.23-.72L8.23 46v-7.02C5.49 36.14 4 32.04 4 27.75 4 19.05 10.94 12 19.5 12S35 19.05 35 27.75z"/><path pid="2" fill-rule="evenodd" _fill="#E4F2ED" d="M38 27.55c0 9.69-8.06 17.39-18 17.39-1.67 0-3.38-.39-4.91-.81L6.91 49v-8.94C3.73 36.9 2 32.33 2 27.55 2 17.86 10.06 10 20 10s18 7.86 18 17.55z"/><path pid="3" fill-rule="evenodd" _fill="#3AB977" d="M43.6 38.23V50l-10.02-5.84c-1.9.54-3.9.84-5.98.84h-.09c1.16-.53 2.26-1.17 3.29-1.91 1.06-.17 2.16-.42 3.04-.8L42 47.2v-9.74c.35-.33.91-.89 1.54-1.63h.01c.05-.06 1.09-1.3 1.53-2.07A20.7 20.7 0 0 0 48.4 22.5c0-11.51-9.31-20.83-20.8-20.83-6.99 0-13.16 3.45-16.93 8.74-1.09.58-2.11 1.25-3.06 2.02C11.28 5.07 18.84 0 27.6 0 39.97 0 50 10.07 50 22.5c0 6.13-2.45 11.67-6.4 15.73zM11.2 31.51c-1.55 0-2.8-1.29-2.8-2.87 0-1.59 1.25-2.87 2.8-2.87 1.55 0 2.8 1.28 2.8 2.87 0 1.58-1.25 2.87-2.8 2.87z"/><path pid="4" fill-rule="evenodd" _fill="#3AB977" d="M27.2 25.77c1.55 0 2.8 1.28 2.8 2.87 0 1.58-1.25 2.87-2.8 2.87-1.55 0-2.8-1.29-2.8-2.87 0-1.59 1.25-2.87 2.8-2.87zM22 28.64c0 1.58-1.25 2.87-2.8 2.87-1.55 0-2.8-1.29-2.8-2.87 0-1.59 1.25-2.87 2.8-2.87 1.55 0 2.8 1.28 2.8 2.87z"/>`
  }
})
