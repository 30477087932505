/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'quote_small': {
    width: 94,
    height: 90,
    viewBox: '0 0 94 90',
    data: `<path pid="0" fill-rule="evenodd" _fill="#F0F1F3" d="M52.09 42.27h20.8c.1.99.16 2 .16 3.02 0 13.91-10.01 25.47-23.16 27.76L58.29 90C78.84 84.56 94 65.71 94 43.27V0H52.09v42.27zm-49.89 0h20.79c.11.99.16 2 .16 3.02 0 13.91-10 25.47-23.15 27.76L8.4 90c20.55-5.44 35.71-24.29 35.71-46.73V0H2.2v42.27z"/>`
  }
})
