/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'expand': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<path pid="0" fill-rule="evenodd" _fill="#C4CAD2" d="M16.38 22.01c-.21.2-.54.2-.75 0l-8.3-8.31a.525.525 0 0 1 0-.75c.2-.21.54-.21.75 0l7.93 7.92 7.92-7.92c.2-.21.54-.21.75 0s.21.55 0 .76l-8.3 8.3zM16 1.07C7.75 1.07 1.07 7.75 1.07 16S7.75 30.93 16 30.93 30.93 24.25 30.93 16 24.25 1.07 16 1.07zM16 32C7.16 32 0 24.84 0 16S7.16 0 16 0s16 7.16 16 16-7.16 16-16 16z"/>`
  }
})
