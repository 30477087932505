/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'arrow-upward': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<path pid="0" clip-rule="evenodd" d="M26.704 10.192L16.705.293a1.026 1.026 0 0 0-1.428 0l-9.999 9.9a.994.994 0 0 0 0 1.414 1.017 1.017 0 0 0 1.429 0l8.275-8.192V31c0 .552.452 1 1.01 1s1.01-.448 1.01-1V3.414l8.275 8.192a1.016 1.016 0 0 0 1.428 0 .993.993 0 0 0-.001-1.414z" _fill="#121313" fill-rule="evenodd"/>`
  }
})
