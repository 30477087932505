/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'long-arrow': {
    width: 100,
    height: 30,
    viewBox: '0 0 100 30',
    data: '<path pid="0" d="M2 13a2 2 0 100 4v-4zm97.414 3.414a2 2 0 000-2.828L86.686.858a2 2 0 10-2.828 2.828L95.172 15 83.858 26.314a2 2 0 102.828 2.828l12.728-12.728zM2 17h96v-4H2v4z" _fill="#fff"/>'
  }
})
