/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'paris-catacombs-tours': {
    width: 29,
    height: 34,
    viewBox: '0 0 29 34',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M7.71 12c1.5 0 2.72 1.23 2.72 2.74 0 1.51-1.22 2.74-2.72 2.74s-2.72-1.23-2.72-2.74c0-1.51 1.22-2.74 2.72-2.74zm0 7.3c2.5 0 4.53-2.05 4.53-4.56s-2.03-4.56-4.53-4.56-4.53 2.05-4.53 4.56 2.03 4.56 4.53 4.56zM20.4 12c1.49 0 2.71 1.23 2.71 2.74 0 1.51-1.22 2.74-2.71 2.74-1.5 0-2.72-1.23-2.72-2.74 0-1.51 1.22-2.74 2.72-2.74zm0 7.3c2.49 0 4.53-2.05 4.53-4.56s-2.04-4.56-4.53-4.56c-2.5 0-4.54 2.05-4.54 4.56s2.04 4.56 4.54 4.56zM14.5 1.82c5.87 0 12.69 4.9 12.69 11.21v8.52c0 3.18-3.35 5.55-6.35 5.55-.5 0-.9.41-.9.91v2.48c0 .68-.29 1.69-1.08 1.69h-.73v-1.83c0-.5-.41-.91-.91-.91s-.91.41-.91.91v1.84l-3.62.02v-1.86c0-.5-.41-.91-.91-.91s-.9.41-.9.91V32.23l-1.81.01c-.07-.15-.04-.68-.03-1.03.01-.24.02-.48.02-.72v-2.48c0-.5-.4-.91-.9-.91-3 0-6.35-2.37-6.35-5.55v-8.52c0-6.31 6.82-11.21 12.69-11.21zM18.86 34c1.68 0 2.89-1.48 2.89-3.51v-1.62c3.96-.43 7.25-3.67 7.25-7.32v-8.52C29 5.57 21.34 0 14.5 0S0 5.57 0 13.03v8.52c0 3.65 3.29 6.89 7.25 7.32v1.62c0 .21-.01.43-.02.64-.04.84-.08 1.72.51 2.34.34.35.8.53 1.39.53h9.73z"/>`
  }
})
