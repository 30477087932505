/* eslint-disable */

var icon = require('vue-svgicon')
icon.register({
  'call-customer-support': {
    width: 40,
    height: 39,
    viewBox: '0 0 40 39',
    data: `<path pid="0" fill-rule="evenodd" _fill="#58B69E" d="M18.52 11.53c-.82 0-1.49.66-1.49 1.46 0 .81.67 1.47 1.49 1.47S20 13.8 20 12.99c0-.8-.661-1.46-1.48-1.46zm6.129 0c-.82 0-1.48.66-1.48 1.46 0 .81.66 1.47 1.48 1.47s1.49-.66 1.49-1.47c.001-.8-.669-1.46-1.49-1.46zm6.141 0c-.82 0-1.48.66-1.48 1.46 0 .81.66 1.47 1.48 1.47s1.48-.66 1.48-1.47c0-.8-.661-1.46-1.48-1.46zm9.21.74C40 5.51 35.129 0 29.14 0h-9.28C13.87 0 9 5.51 9 12.27s4.87 12.27 10.86 12.27h6.71l4.49 5.07c.22.25.52.39.82.39.64 0 1.15-.58 1.15-1.3v-4.98C37.22 21.88 40 17.39 40 12.27zm-1.28 0c0 4.64-2.65 8.79-6.54 10.24-.26.1-.43.39-.43.68v5.17l-4.45-5.03a.619.619 0 0 0-.47-.19H19.9c-5.26 0-9.57-4.83-9.57-10.82 0-5.94 4.27-10.82 9.57-10.82h9.28c5.259-.05 9.54 4.78 9.54 10.77zm-34.6 4.16c.08 0 .21.02.33.13l2.83 5.18c.15.29.21.53.16.69-.05.14-.18.22-.21.24l-1.34.45c-1.74.58-1.74.58-1.83.94-.43 1.4.94 3.63 2.16 5.26 1.36 1.82 4.48 5.26 6.52 5.26l.21-.02.37-.04 1.56-2.81c.03-.04.14-.17.32-.17.16 0 .38.1.61.28l4.67 3.65c.13.21.1.49-.1.66l-.07.06c-.04.04-1.11 1.14-3.33 1.35-.23.02-.46.03-.7.03-3.55 0-7.89-2.31-10.79-5.74-3.19-3.76-4.68-8.62-3.8-12.37.48-2.05 1.9-2.8 1.95-2.83l.07-.03.05-.04a.55.55 0 0 1 .36-.13zM16.279 39c.28 0 .56-.01.83-.04 2.6-.24 3.99-1.52 4.23-1.77.79-.69.89-1.88.21-2.69l-.05-.05-4.8-3.76c-.5-.38-1.01-.58-1.5-.58-.99 0-1.5.76-1.55.84l-1.19 2.15c-1.05-.3-3.18-2.09-5.08-4.63-1.28-1.71-1.88-3-1.95-3.68l2.3-.78.041-.01c.08-.03.75-.33 1.02-1.07.2-.57.12-1.19-.24-1.87l-2.91-5.32-.04-.06A1.957 1.957 0 0 0 4.12 15c-.42 0-.84.14-1.19.39-.37.2-2.07 1.26-2.65 3.74-.97 4.18.63 9.52 4.1 13.62C7.6 36.55 12.27 39 16.279 39z"/>`
  }
})
